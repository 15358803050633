import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateT1Half({
  subtitle,
  itemTitle,
  image,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t0_cover_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-half-contents">
        <img
          className="template-half-contents-img"
          src={`${GET_IMAGE_URL}${image[0]}.png`}
          alt=""
        />
        <img
          className="template-half-contents-img"
          src={`${GET_IMAGE_URL}${image[1]}.png`}
          alt=""
        />
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateT1Half.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: [],
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

TemplateT1Half.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default TemplateT1Half;
