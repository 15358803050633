import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import CloseIcon from "assets/images/closeicon.png";

import "./style.css";
import "../common.css";

const calcKMData = [
  {
    vehicle: "New KONA Electric",
    maxDays: 514,
    maxDistance: 514,
  },
  {
    vehicle: "KONA Electric",
    maxDays: 484,
    maxDistance: 484,
  },
  {
    vehicle: "IONIQ 5",
    maxDays: 507,
    maxDistance: 507,
  },
  {
    vehicle: "IONIQ 5 N",
    maxDays: 448,
    maxDistance: 448,
  },
  {
    vehicle: "IONIQ 6",
    maxDays: 614,
    maxDistance: 614,
  },
];

const calcMileData = [
  {
    vehicle: "New KONA Electric",
    maxDays: 319,
    maxDistance: 319,
  },
  {
    vehicle: "KONA Electric",
    maxDays: 300,
    maxDistance: 300,
  },
  {
    vehicle: "IONIQ 5",
    maxDays: 315,
    maxDistance: 315,
  },
  {
    vehicle: "IONIQ 5 N",
    maxDays: 278,
    maxDistance: 278,
  },
  {
    vehicle: "IONIQ 6",
    maxDays: 338,
    maxDistance: 338,
  },
];

function CalculatorPage({ calculator, calcDetail, index, country, setFlag }) {
  const calcData = country === "UK" ? calcMileData : calcKMData;
  return (
    <NewWindow
      title="calculator_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="calculator-popup-contents">
        <div className="calculator-popup-contents-back">
          <img src={CloseIcon} alt="" />
        </div>
        <div className="white b2">{calcDetail[0]}</div>
        <div className="white b1">
          <span className="h1">
            {Math.trunc(calcData[index].maxDistance / 20)}
          </span>
          <span>
            {"   "}
            {calcDetail[1]}*
          </span>
        </div>
        {index === 0 && (
          <img
            className="calculator-image"
            src={`${GET_IMAGE_URL}NEW_KONA_ELECTRIC_calc.png`}
            alt=""
          />
        )}
        {index === 1 && (
          <img
            className="calculator-image"
            src={`${GET_IMAGE_URL}KONA_ELECTRIC_calc.png`}
            alt=""
          />
        )}
        {index === 2 && (
          <img
            className="calculator-image"
            src={`${GET_IMAGE_URL}IONIQ_5_calc.png`}
            alt=""
          />
        )}
        {index === 3 && (
          <img
            className="calculator-image"
            src={`${GET_IMAGE_URL}IONIQ_5_N_calc.png`}
            alt=""
          />
        )}
        {index === 4 && (
          <img
            className="calculator-image"
            src={`${GET_IMAGE_URL}IONIQ_6_calc.png`}
            alt=""
          />
        )}
        <div className="progress-bar">
          <div className="b6 light-gray progress-bar-min">
            0 {country === "UK" ? "mile" : "km"}
          </div>
          <div className="progress-bar-filled"> </div>
          <div className="progress-bar-thumb">
            <div className="h6 light-gray progress-bar-thumb-range">20</div>
          </div>
          <div className="progress-bar-unfilled"> </div>
          <div className="b6 light-gray progress-bar-max">
            {calcData[index].maxDistance}
            {country === "UK" ? "mile" : "km"}
          </div>
        </div>
        <div className="light-gray h5 calculator-popup-contents-desc">
          {calcDetail[2]}
        </div>
        <div className="sand b4 calculator-popup-contents-detail">
          {calculator}
        </div>
      </div>
    </NewWindow>
  );
}

CalculatorPage.defaultProps = {
  calculator: "",
  calcDetail: "",
  index: 0,
  country: "",
  setFlag: () => {},
};

CalculatorPage.propTypes = {
  calculator: PropTypes.string,
  calcDetail: PropTypes.string,
  index: PropTypes.number,
  country: PropTypes.string,
  setFlag: PropTypes.string,
};

export default CalculatorPage;
