import PropTypes from "prop-types";

// import HomeIcon from "assets/images/preview/Home.png";
// import BarIcon from "assets/images/preview/Bar.png";
// import RotationIcon from "assets/images/preview/360.png";
// import CalculatorIcon from "assets/images/preview/Calculator.png";
// import MenuIcon from "assets/images/preview/Menu.png";

import "./style.css";
import "../common.css";

import { getPageTitle, getPageIndex } from "utils/functions/page";

import ElectrifiedNav from "./electrified";
import PowertrainNav from "./powertrain";
import BluelinkNav from "./bluelink";
import ChargeMyHyundaiNav from "./chargemyhyundai";
// import DefaultNav from "./defaultNav";

function Nav({ pageTitle, pageClass, electrified, bluelink, charge }) {
  return (
    <div>
      {/* <DefaultNav /> */}
      {getPageTitle(pageTitle[0]) === "Electrified" && (
        <ElectrifiedNav
          pageTitle={pageTitle[getPageIndex(pageTitle[0]) + 1]}
          buttons={pageTitle[0] === "All About EV" ? electrified : []}
        />
      )}
      {getPageTitle(pageTitle[0]) === "Powertrain" && (
        <PowertrainNav pageTitle={pageTitle[getPageIndex(pageTitle[0]) + 1]} />
      )}
      {pageTitle[0] === "Bluelink" && (
        <BluelinkNav pageTitle={pageTitle[1]} buttons={bluelink} />
      )}
      {pageTitle[0] === "Charge myHyundai" && (
        <ChargeMyHyundaiNav pageTitle={pageTitle[2]} buttons={charge} />
      )}
    </div>
    // <nav className="nav">
    //   <div className="nav-left">
    //     <img className="nav-img" alt="home" src={HomeIcon} />
    //     <img className="left-bar" alt="bar" src={BarIcon} />
    //     <h6>{electrified}</h6>
    //   </div>
    //   <div className="nav-center">
    //     <span className="highlights">
    //       <h6 className={pageClass === "Highlights" ? "active" : "passive"}>
    //         Highlights
    //       </h6>
    //     </span>
    //     <span className="charging">
    //       <h6 className={pageClass === "Charging" ? "active" : "passive"}>
    //         Charging
    //       </h6>
    //     </span>
    //     <span className="benefits">
    //       <h6 className={pageClass === "Benefits" ? "active" : "passive"}>
    //         Benefits
    //       </h6>
    //     </span>
    //   </div>
    //   <div className="nav-right">
    //     <span className="nav-right-item">
    //       <img className="nav-img" alt="rotation" src={RotationIcon} />
    //     </span>
    //     <span className="nav-right-item">
    //       <img className="nav-img" alt="calcul" src={CalculatorIcon} />
    //     </span>
    //     <span className="nav-right-item">
    //       <img className="menu-img" alt="menu" src={MenuIcon} />
    //     </span>
    //   </div>
    // </nav>
  );
}

Nav.defaultProps = {
  pageTitle: "",
  pageClass: "",
  electrified: [],
  bluelink: [],
  charge: [],
};

Nav.propTypes = {
  pageTitle: PropTypes.string,
  pageClass: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default Nav;
