import SignIn from "layouts/authentication/0000_Signin";
import Download from "layouts/amin/4000_Download";

// @mui icons
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";

const routes = [
  {
    type: "none",
    name: "Sign In",
    key: "sign in",
    collapse: [
      {
        name: "SignIn",
        key: "sign in",
        route: "/admin",
        component: <SignIn />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Download",
    key: "download",
    route: "/admin/download",
    icon: <FileDownloadRoundedIcon fontSize="medium" />,
    noCollapse: true,
    collapse: [
      {
        name: "Download",
        key: "download",
        route: "/admin/download",
        component: <Download />,
      },
    ],
  },
];

export default routes;
