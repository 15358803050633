import Grid from "@mui/material/Grid";
import { Card, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Ta/bles/DataTable";

// import useDealerStore from "utils/stores/dealer.store";

import { useLayoutEffect, useState } from "react";
// import { getCountryCookie } from "utils/functions/cookie";
import {
  getAppIDList,
  getSubscribeLogs,
  getVersion,
  getDashboardData,
  getAppIDByDealership,
  editSubscribe,
  getHMEDashboardData,
} from "utils/functions/axios";
import { getCountryDefaultValue, getCountry } from "utils/functions/country";

import useLanguageStore from "utils/stores/language.store";
import CountrySelectorCard from "../3000_Translations/components/CountrySelectCard";

import searchSvg from "../../../assets/images/search.svg";

const checkList = ["Electrified", "Powertrain", "Connectivity", "Integration"];
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const subscribeCheckTitle = "Billing Infomation";
const subscribeCheckComment =
  "If you subscribe the app, a subscription fee for full month will be charged on upcoming 15th. Do you want to add the subscription?";

const unsubscribeCheckTitle = "Unsubscribe";
const unsubscribeCheckComment = "Do you want to unsubscribe the app?";

const subscribeTitle = "Successfully subscribed";
const subscribeComment = "A subscription has been added.";

const unsubscribeTitle = "Successfully unsubscribed";
const unsubscribeComment = "A subscription has been removed.";

const errorTitle = "Please try again";
const errorComment = "Failed to save the subscription status. Please try again";

function ElectrifiedSetting() {
  // const [dealershipCountryList, setDealershipCountryList] = useState([]);

  const [countryInfo, setCountryInfo] = useState({});
  const [versions, setVersions] = useState({});
  const [deviceCount, setDeviceCount] = useState([]);
  const [appID, setAppID] = useState(null);
  const [dealershipDashboardData, setDealershipDashboardData] = useState(null);
  const [monthDays, setMonthDays] = useState(0);
  const [logs, setLogs] = useState({});
  const [summaryUses, setSummaryUses] = useState(0);
  const [summaryDashboardData, setSummaryDashboardData] = useState({
    subscribe: 0,
    running: 0,
    unsubscribe: 0,
    totalActive: "0 / 0",
    avarageRunning: "0h 0m",
  });
  const [switching, setSwitching] = useState(false);

  const [appIDList, setAppIDList] = useState(null);
  const [filteredAppIDList, setFilteredAppIDList] = useState(null);
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertResult, setAlertResult] = useState(false);
  const [init, setInit] = useState(false);

  const [alertTitle, setAlertTitle] = useState("");
  const [alertComment, setAlertComment] = useState("");
  const [isAddedQuantity, setIsAddedQuantity] = useState(false);

  const [hmeData, setHMEData] = useState([]);
  const [summaryHMEData, setSummaryHMEData] = useState(null);
  const [hmeLogs, setHMELogs] = useState({});

  const { language, change, setChange, temporaryNotice, setTemporaryNotice } =
    useLanguageStore();

  const convertSecondToTime = (second) => {
    if (second < 60 && second > 0) return "1m";
    if (second <= 0) return "0m";

    const secondsPerMinute = 60;
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;

    const totalDays = Math.floor(second / secondsPerDay);
    const totalHour = Math.floor((second % secondsPerDay) / secondsPerHour);
    const totalMinute = Math.floor(
      (second % secondsPerHour) / secondsPerMinute
    );

    if (totalDays > 0) return `${totalDays}d ${totalHour}h ${totalMinute}m`;
    if (totalHour > 0) return `${totalHour}h ${totalMinute}m`;
    return `${totalMinute}m`;
  };

  const HMEDashboardData = async () => {
    if (hmeData.length > 0) return;

    let result = await getHMEDashboardData();

    if (process.env.REACT_APP_ENV === "prd") {
      result = result.filter((item) => item.country !== "TH");
    }

    let summarySubscription = 0;
    let summaryUnsubscription = 0;
    let summaryRunningDate = 0;
    let summaryAverageTime = 0;
    let summaryCount = 0;
    let subscriptionCount = 0;

    for (let i = 0; i < result.length; i += 1) {
      summarySubscription += result[i].subscription;
      summaryUnsubscription += result[i].unsubscription;
      summaryRunningDate += result[i].summaryDate;
      summaryAverageTime += result[i].averageTime;
      summaryCount += result[i].count;

      if (result[i].subscription > 0) subscriptionCount += 1;
    }

    // summaryRunningDate /= summaryCount;
    summaryRunningDate =
      summaryCount === 0 ? 0 : summaryRunningDate / subscriptionCount;
    // summaryAverageTime /= summaryCount;
    summaryAverageTime =
      summaryCount === 0 ? 0 : summaryAverageTime / subscriptionCount;
    summaryAverageTime = Math.ceil(summaryAverageTime);

    setHMEData(result);
    setSummaryHMEData({
      subscription: summarySubscription,
      unsubscription: summaryUnsubscription,
      summaryDate: Math.ceil(summaryRunningDate),
      averageTime: convertSecondToTime(summaryAverageTime),
    });
  };

  const HMELogs = async () => {
    if (hmeLogs.from) return;

    const result = await getSubscribeLogs("HME");
    setHMELogs(result.data);
  };

  const dashboardData = async () => {
    // console.log(monthDays);
    const country = language;

    const dealershipData = await getDashboardData(country);
    if (dealershipData.result) {
      setDealershipDashboardData(dealershipData.data);

      const today = new Date();
      const last = new Date(today.getFullYear(), today.getMonth(), 0);

      setMonthDays(last.getDate());

      let subscribe = 0;
      let running = 0;
      let unsubscribe = 0;
      let totalActive = 0;
      let avarageRunning = 0;
      const dataLength = dealershipData.data.length;

      for (let i = 0; i < dataLength; i += 1) {
        subscribe += dealershipData.data[i].data.currentAppState.subscribe;
        running += dealershipData.data[i].data.currentAppState.current;
        unsubscribe +=
          dealershipData.data[i].data.currentAppState.summary -
          dealershipData.data[i].data.currentAppState.subscribe;
        totalActive += dealershipData.data[i].data.summaryDate;
        avarageRunning += dealershipData.data[i].data.avarageTime;
      }

      totalActive = subscribe === 0 ? 0 : Math.ceil(totalActive / subscribe);
      avarageRunning =
        subscribe === 0 ? 0 : Math.ceil(avarageRunning / subscribe);

      setSummaryDashboardData({
        subscribe,
        running,
        unsubscribe,
        totalActive: `${totalActive} / ${last.getDate()}`,
        avarageRunning: `${Math.floor(avarageRunning / 60)}h ${
          avarageRunning % 60
        }m`,
      });
    }
  };

  const appIdData = async () => {
    const country = language;

    // const countryList =
    //   dealershipCountryList.length > 0 ? dealershipCountryList : countries;

    // if (!countryList.includes(country)) return;

    const appIDResult = await getAppIDByDealership(country);
    setAppIDList(appIDResult);
    setFilteredAppIDList(appIDResult);
  };

  const handleSwitch = (item) => {
    const data = { ...item };
    data.quantity = data.quantity > 0 ? 0 : 1;

    setToggle(true);
    setLoading(false);
    setSelected(data);
    setAlertResult(false);
    setIsAddedQuantity(data.quantity > 0);

    if (data.quantity <= 0) {
      setAlertTitle(unsubscribeCheckTitle);
      setAlertComment(unsubscribeCheckComment);
    } else {
      setAlertTitle(subscribeCheckTitle);
      setAlertComment(subscribeCheckComment);
    }
  };

  const handleSearch = (e) => {
    const s = e.target.value;
    setSearch(s);

    if (s === "") setFilteredAppIDList(appIDList);
    else
      setFilteredAppIDList(
        appIDList.filter(
          (item) => item.appId.includes(s) || item.dealershipCode.includes(s)
        )
      );
  };

  const handleEditAppId = async () => {
    setLoading(true);

    const body = {
      id: selected.dealerId,
      appId: selected.appId,
      quantity: selected.quantity,
    };

    // console.log("test", search, body);

    const result = await editSubscribe(body);

    if (result.result) {
      await dashboardData();
      await appIdData();

      if (isAddedQuantity) {
        setAlertTitle(subscribeTitle);
        setAlertComment(subscribeComment);
      } else {
        setAlertTitle(unsubscribeTitle);
        setAlertComment(unsubscribeComment);
      }
    } else {
      setAlertTitle(errorTitle);
      setAlertComment(errorComment);
    }

    setAlertResult(true);
    setLoading(false);
  };

  const handleClose = () => setToggle(false);

  function toggleAlertComponent() {
    return loading ? (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "130px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography variant="b7">Saving...</MDTypography>
        </Card>
      </MDBox>
    ) : (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              {alertTitle}
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              {alertComment}
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            {!alertResult && (
              <MDButton
                style={{
                  float: "right",
                  color: "rgba(0,0,0,0.4)",
                  width: "110px",
                }}
                onClick={handleClose}
              >
                Cancel
              </MDButton>
            )}
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={alertResult ? handleClose : handleEditAppId}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  const temporaryNoticeModalTitle = "This is a temporary page";
  const temporaryNoticeModalComment =
    "This is a temporary page for testing purpose only. Please access the platform and install the app via Dealer Portal.";
  function temporaryNoticeModal() {
    return (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              {temporaryNoticeModalTitle}
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              {temporaryNoticeModalComment}
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={() => {
                setTemporaryNotice(false);
              }}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  useLayoutEffect(() => {
    if (!change && init) return;

    // const country = localStorage.getItem("country");
    // const { country: dealerCountry } = dealer;
    // const countryCookie = getCountryCookie;

    // const country = countryCookie !== undefined ? countryCookie : dealerCountry;

    const country = language;

    if (!country || country === "") {
      return;
    }

    const setDashboards = async () => {
      setSwitching(true);

      if (language === "HME") {
        await HMEDashboardData();
        await HMELogs();

        const version = await getVersion("DE");
        setVersions(version);
        setSwitching(false);

        return;
      }

      setCountryInfo(getCountry(country));

      // const dealershipCountries = await getDealershipCountryList();
      // setDealershipCountryList(dealershipCountries);

      const version = await getVersion(country);
      setVersions(version);

      const appIDs = await getAppIDList(country);
      if (appIDs.result) {
        const device = [
          appIDs.data.electrified,
          appIDs.data.powertrain,
          appIDs.data.connectivity,
          appIDs.data.integration,
        ];
        const total = device[0] + device[1] + device[2] + device[3];

        setAppID(device);
        setDeviceCount(device);
        setSummaryUses(total);
      }

      // const dealershipData = await getDashboardData(country);
      // if (dealershipData.result) {
      //   setDealershipDashboardData(dealershipData.data);

      //   const today = new Date();
      //   const last = new Date(today.getFullYear(), today.getMonth(), 0);

      //   setMonthDays(last.getDate());

      //   let subscribe = 0;
      //   let running = 0;
      //   let unsubscribe = 0;
      //   let totalActive = 0;
      //   let avarageRunning = 0;
      //   const dataLength = dealershipData.data.length;

      //   for (let i = 0; i < dataLength; i += 1) {
      //     subscribe += dealershipData.data[i].data.currentAppState.subscribe;
      //     running += dealershipData.data[i].data.currentAppState.current;
      //     unsubscribe +=
      //       dealershipData.data[i].data.currentAppState.summary -
      //       dealershipData.data[i].data.currentAppState.subscribe;
      //     totalActive += dealershipData.data[i].data.summaryDate;
      //     avarageRunning += dealershipData.data[i].data.avarageTime;
      //   }

      //   totalActive = Math.ceil(totalActive / dataLength);
      //   avarageRunning = Math.ceil(avarageRunning / dataLength);

      //   setSummaryDashboardData({
      //     subscribe,
      //     running,
      //     unsubscribe,
      //     totalActive: `${Math.ceil(
      //       totalActive / dealershipData.data.length
      //     )} / ${last.getDate()}`,
      //     avarageRunning: `${Math.floor(avarageRunning / 60)}h ${
      //       avarageRunning % 60
      //     }m`,
      //   });
      // }
      await dashboardData();

      await appIdData();

      const activeLogs = await getSubscribeLogs(country);

      if (activeLogs.result) {
        setLogs(activeLogs.data);
      } else {
        setLogs([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      }

      setSwitching(false);
    };

    setDashboards();
    setChange(false);
    setInit(true);
  }, [change, language]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={-4} py={1}>
        <MDBox mt={2}>
          <Grid container spacing={1.5}>
            <CountrySelectorCard />
          </Grid>
        </MDBox>
        {language !== "HME" && (
          <MDBox style={{ marginTop: "24px" }}>
            <MDTypography variant="b7" color="darkGray">
              Current Version
            </MDTypography>
            {versions !== undefined && versions !== null && (
              <Grid container mt="20px">
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        App Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.app}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Asset Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.asset}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Menu Setup Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.display}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Translations Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.translations}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            )}
            <Grid container>
              {appID !== null && appID !== undefined && (
                <Grid item pr="12px" xl={3}>
                  <Card style={{ overflow: "hidden" }}>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid container>
                          <Grid item pr="12px" md={6} xl={6}>
                            <MDTypography
                              variant="b5"
                              style={{ color: "#7B809A" }}
                            >
                              Type
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={6}>
                            <MDTypography
                              variant="b5"
                              style={{
                                color: "#7B809A",
                                textAlign: "right",
                              }}
                            >
                              Number of uses
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    {appID !== null &&
                      checkList.map((item) => (
                        <Grid key={`${countryInfo.name}_${item}`}>
                          <MDBox
                            px="24px"
                            py="16px"
                            style={{ borderBottom: "2px solid #F0F2F5" }}
                          >
                            <Grid container>
                              {/* <Grid item pr="12px" md={6} xl={4}>
                                <MDTypography style={{ color: "#1C1B1B" }}>
                                  {countryInfo.name}
                                </MDTypography>
                              </Grid> */}
                              <Grid item pr="12px" md={6} xl={8}>
                                <MDTypography style={{ color: "#1C1B1B" }}>
                                  {item}
                                </MDTypography>
                              </Grid>
                              <Grid item pr="12px" md={6} xl={4}>
                                <MDTypography
                                  style={{
                                    color: "#1C1B1B",
                                    textAlign: "right",
                                  }}
                                >
                                  {item === "Electrified" && deviceCount[0]}
                                  {item === "Powertrain" && deviceCount[1]}
                                  {item === "Connectivity" && deviceCount[2]}
                                  {item === "Integration" && deviceCount[3]}
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Grid>
                      ))}
                    <Grid style={{ backgroundColor: "#F6F3F2" }}>
                      <MDBox px="24px" py="16px">
                        <Grid container>
                          {/* <Grid item pr="12px" md={6} xl={4}>
                            <MDTypography style={{ color: "#1C1B1B" }}>
                              {}
                            </MDTypography>
                          </Grid> */}
                          <Grid item pr="12px" md={6} xl={8}>
                            <MDTypography
                              fontWeight="bold"
                              style={{ color: "#1C1B1B" }}
                            >
                              Total
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={4}>
                            <MDTypography
                              fontWeight="bold"
                              style={{
                                color: "#1C1B1B",
                                textAlign: "right",
                              }}
                            >
                              {summaryUses}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Card>
                </Grid>
              )}
              {dealershipDashboardData !== null &&
                dealershipDashboardData !== undefined && (
                  <Grid item pr="12px" xl={9}>
                    <Card style={{ overflow: "hidden" }}>
                      <Grid>
                        <MDBox
                          px="24px"
                          py="16px"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                            borderBottom: "2px solid #F0F2F5",
                          }}
                        >
                          <Grid
                            container
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid item pr="12px" md={6} xl={6}>
                              <MDTypography
                                variant="b5"
                                style={{ color: "#7B809A" }}
                              >
                                Dealership Name
                              </MDTypography>
                            </Grid>
                            <Grid item pr="12px" md={6} xl={2}>
                              <MDTypography
                                variant="b5"
                                style={{
                                  color: "#7B809A",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                Subscription
                              </MDTypography>
                            </Grid>
                            <Grid item pr="12px" md={6} xl={2}>
                              <MDTypography
                                variant="b5"
                                style={{
                                  color: "#7B809A",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                Apps running
                              </MDTypography>
                            </Grid>
                            <Grid item pr="12px" md={6} xl={2}>
                              <MDTypography
                                variant="b5"
                                style={{
                                  color: "#7B809A",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                Apps unsubscribed
                              </MDTypography>
                            </Grid>
                            {/* <Grid item pr="12px" md={6} xl={2}>
                              <MDTypography
                                variant="b5"
                                style={{
                                  color: "#7B809A",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {"Total active days\n(past month)"}
                              </MDTypography>
                            </Grid>
                            <Grid item pr="12px" md={6} xl={2}>
                              <MDTypography
                                variant="b5"
                                style={{
                                  color: "#7B809A",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {"Average running time\n(past month)"}
                              </MDTypography>
                            </Grid> */}
                          </Grid>
                        </MDBox>
                      </Grid>
                      {dealershipDashboardData.length === 0 ? (
                        <Grid style={{ height: "288px" }}>
                          <MDBox
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <MDTypography
                              style={{
                                fontFace: "Hyundai Sans Head",
                                fontSize: "20px",
                                fontWeight: "400",
                                color: "rgba(17, 17, 17, 0.4)",
                                marginBottom: "8px",
                              }}
                            >
                              No Data
                            </MDTypography>
                            <MDTypography
                              style={{
                                fontFace: "Hyundai Sans Head",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "rgba(17, 17, 17, 0.15)",
                                marginBottom: "8px",
                              }}
                            >
                              There are no data yet
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            style={{ overflowY: "scroll", height: "230px" }}
                          >
                            {dealershipDashboardData !== null &&
                              dealershipDashboardData !== undefined &&
                              dealershipDashboardData.map((item) => (
                                <MDBox
                                  key={`${item.dealershipCode}`}
                                  px="24px"
                                  py="16px"
                                  style={{
                                    borderBottom: "2px solid #F0F2F5",
                                  }}
                                >
                                  <Grid
                                    container
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid item pr="12px" md={6} xl={6}>
                                      {item.dealershipName.length > 0 ? (
                                        <>
                                          <MDTypography
                                            style={{
                                              color: "#1C1B1B",
                                              // whiteSpace: "nowrap",
                                            }}
                                          >
                                            {item.dealershipName}
                                          </MDTypography>
                                          <MDTypography
                                            style={{
                                              color: "#1C1B1B",
                                              // whiteSpace: "nowrap",
                                            }}
                                          >
                                            {`(${item.dealershipCode})`}
                                          </MDTypography>
                                        </>
                                      ) : (
                                        <MDTypography
                                          style={{
                                            color: "#1C1B1B",
                                            // whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.dealershipCode}
                                        </MDTypography>
                                      )}
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.data.currentAppState.subscribe}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.data.currentAppState.current}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.data.currentAppState.summary -
                                          item.data.currentAppState.subscribe}
                                      </MDTypography>
                                    </Grid>
                                    {/* <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.data.summaryDate}/{monthDays}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {Math.floor(item.data.avarageTime / 60)}
                                        {"h "}
                                        {item.data.avarageTime % 60}m
                                      </MDTypography>
                                    </Grid> */}
                                  </Grid>
                                </MDBox>
                              ))}
                          </Grid>
                          <Grid style={{ backgroundColor: "#F6F3F2" }}>
                            {dealershipDashboardData !== null &&
                              dealershipDashboardData !== undefined && (
                                <MDBox
                                  px="24px"
                                  py="16px"
                                  style={{
                                    height: "58px",
                                    borderBottom: "2px solid #F0F2F5",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item pr="12px" md={6} xl={6}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{ color: "#1C1B1B" }}
                                      >
                                        Total
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {summaryDashboardData.subscribe}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {summaryDashboardData.running}
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                        }}
                                      >
                                        {summaryDashboardData.unsubscribe}
                                      </MDTypography>
                                    </Grid>
                                    {/* <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                          height: "18px",
                                        }}
                                      >
                                        {summaryDashboardData.totalActive}
                                      </MDTypography>
                                      <MDTypography
                                        style={{
                                          textAlign: "center",
                                          color: "#7B809A",
                                          fontSize: "10px",
                                        }}
                                      >
                                        (Average)
                                      </MDTypography>
                                    </Grid>
                                    <Grid item pr="12px" md={6} xl={2}>
                                      <MDTypography
                                        fontWeight="bold"
                                        style={{
                                          color: "#1C1B1B",
                                          textAlign: "center",
                                          height: "18px",
                                        }}
                                      >
                                        {summaryDashboardData.avarageRunning}
                                      </MDTypography>
                                      <MDTypography
                                        style={{
                                          textAlign: "center",
                                          color: "#7B809A",
                                          fontSize: "10px",
                                        }}
                                      >
                                        (Average)
                                      </MDTypography>
                                    </Grid> */}
                                  </Grid>
                                </MDBox>
                              )}
                          </Grid>
                        </>
                      )}
                    </Card>
                  </Grid>
                )}
            </Grid>
            {/* {dealershipCountryList.includes(language) && filteredAppIDList && ( */}
            {filteredAppIDList && (
              <Grid mt="10px" container>
                <Grid item pr="12px" xl={12}>
                  <Card>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item pr="12px" md={6} xl={8}>
                            <MDTypography
                              // variant="b5"
                              style={{ color: "#7e7e7e", fontSize: "14px" }}
                            >
                              Subscribed Apps ({summaryDashboardData.subscribe})
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={4}>
                            <TextField
                              type="text"
                              label="Search"
                              placeholder="Search by Dealership name or App ID"
                              onChange={handleSearch}
                              fullWidth
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={searchSvg} alt="" />
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item pr="12px" md={6} xl={3.5}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                              }}
                            >
                              Dealership Name
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={5}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              App ID
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1.25}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              App Version
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1.25}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              Last execution
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              Subscription
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    <Grid style={{ overflowY: "auto", height: "230px" }}>
                      {filteredAppIDList.length > 0 ? (
                        filteredAppIDList.map((item) => (
                          <MDBox
                            key={`${item.dealershipCode} - ${item.appId}`}
                            px="24px"
                            py="16px"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "60px",
                              borderBottom: "2px solid #F0F2F5",
                            }}
                          >
                            <Grid
                              container
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item pr="12px" md={6} xl={3.5}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.dealershipName}
                                  </MDTypography>
                                  {item.dealershipCode.length > 0 && (
                                    <MDTypography
                                      // variant="b5"
                                      style={{
                                        color: "#344767",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {`(${item.dealershipCode})`}
                                    </MDTypography>
                                  )}
                                </Grid>
                                <Grid item pr="12px" md={6} xl={5}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.appId}
                                  </MDTypography>
                                </Grid>
                                <Grid item pr="12px" md={6} xl={1.25}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.appVersion}
                                  </MDTypography>
                                </Grid>
                                <Grid item pr="12px" md={6} xl={1.25}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.executeDate}
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  pr="12px"
                                  md={6}
                                  xl={1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Switch
                                    value={item.appId}
                                    checked={item.quantity > 0}
                                    color="blue"
                                    onClick={() => handleSwitch(item)}
                                  />
                                  {/* <MDBox
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      backgroundColor:
                                        item.quantity > 0
                                          ? "#00aad2"
                                          : "#dddddd",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => handleSwitch(-1, item)}
                                  >
                                    <MDTypography
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      -
                                    </MDTypography>
                                  </MDBox> */}
                                  {/* <MDTypography
                                    px="10px"
                                    style={{
                                      width: "60px",
                                      color: "#1C1B1B",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.quantity}
                                  </MDTypography> */}
                                  {/* <MDBox
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      backgroundColor: "#00aad2",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => handleSwitch(1, item)}
                                  >
                                    <MDTypography
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      +
                                    </MDTypography>
                                  </MDBox> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </MDBox>
                        ))
                      ) : (
                        <MDBox
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "20px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.4)",
                              marginBottom: "8px",
                            }}
                          >
                            No Data
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.15)",
                              marginBottom: "8px",
                            }}
                          >
                            There are no data yet
                          </MDTypography>
                        </MDBox>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            )}
            <MDBox style={{ marginTop: "24px" }}>
              <MDTypography variant="b7" color="darkGray">
                {logs.from !== undefined
                  ? `Number of subscribed apps : ${
                      month[parseInt(logs.from.substring(3, 5), 10) - 1]
                    }. ${logs.from.substring(6, 11)} ~ ${
                      month[parseInt(logs.to.substring(3, 5), 10) - 1]
                    }. ${logs.to.substring(6, 11)}`
                  : "Number of subscribed apps : "}
              </MDTypography>
              <Grid container mt="20px">
                <Grid item pr="12px" xl={12}>
                  <Card style={{ overflow: "hidden" }}>
                    <MDBox>
                      <Grid
                        container
                        style={{ borderBottom: "2px solid #F0F2F5" }}
                        alignItems="center"
                      >
                        {logs.result !== null &&
                          logs.result !== undefined &&
                          logs.result.map((log, index) => (
                            <Grid item pt="25px" pb="25px" xl={1}>
                              <MDTypography
                                align="center"
                                variant="b5"
                                style={{ color: "#7B809A" }}
                              >
                                {log >= 0 &&
                                  `${
                                    month[
                                      (parseInt(logs.from.substring(3, 5), 10) +
                                        (11 - index) -
                                        1) %
                                        12
                                    ]
                                  }.${
                                    parseInt(logs.from.substring(6, 11), 10) +
                                    (parseInt(logs.from.substring(3, 5), 10) +
                                      (11 - index) -
                                      1 >=
                                    12
                                      ? 1
                                      : 0)
                                  }`}
                              </MDTypography>
                            </Grid>
                          ))}
                      </Grid>
                    </MDBox>
                    <MDBox>
                      <Grid container>
                        {logs.result !== null &&
                          logs.result !== undefined &&
                          logs.result.map((log, index) => (
                            <Grid item pt="25px" pb="25px" xl={1}>
                              <MDTypography
                                align="center"
                                // color="darkGray"
                                // fontWeight="bold"
                              >
                                {`${logs.result[11 - index]}`}
                              </MDTypography>
                            </Grid>
                          ))}
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}
        {language === "HME" && (
          <MDBox style={{ marginTop: "24px" }}>
            <MDTypography variant="b7" color="darkGray">
              Current Version
            </MDTypography>
            {versions !== undefined && versions !== null && (
              <Grid container mt="20px">
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        App Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.app}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Asset Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        v {versions.asset}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Menu Setup Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        Varies by region
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item pr="12px" mb="24px" md={6} xl={3}>
                  <Card>
                    <MDBox px="24px" py="16px">
                      <MDTypography variant="b7" color="deepGray">
                        Translations Version
                      </MDTypography>
                      <MDTypography mt="10px" variant="b7" color="darkGray">
                        Varies by region
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            )}
            <Grid container>
              {hmeData !== null && hmeData !== undefined && hmeData.length > 0 && (
                <Grid item pr="12px" xl={12}>
                  <Card style={{ overflow: "hidden" }}>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item pr="12px" md={6} xl={6}>
                            <MDTypography
                              variant="b5"
                              style={{ color: "#7B809A" }}
                            >
                              Country
                            </MDTypography>
                          </Grid>
                          {/* <Grid item pr="12px" md={6} xl={3}>
                            <MDTypography
                              variant="b5"
                              style={{ color: "#7B809A" }}
                            >
                              Dealership Code
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={4.5}>
                            <MDTypography
                              variant="b5"
                              style={{ color: "#7B809A" }}
                            >
                              App ID
                            </MDTypography>
                          </Grid> */}
                          <Grid item pr="12px" md={6} xl={1.5}>
                            <MDTypography
                              variant="b5"
                              style={{
                                color: "#7B809A",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              Subscription
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1.5}>
                            <MDTypography
                              variant="b5"
                              style={{
                                color: "#7B809A",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              Apps unsubscribed
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1.5}>
                            <MDTypography
                              variant="b5"
                              style={{
                                color: "#7B809A",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {"Total active days\n(This month)"}
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={1.5}>
                            <MDTypography
                              variant="b5"
                              style={{
                                color: "#7B809A",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {"Average running time\n(This month)"}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    {hmeData.length === 0 ? (
                      <Grid style={{ height: "288px" }}>
                        <MDBox
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "20px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.4)",
                              marginBottom: "8px",
                            }}
                          >
                            No Data
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.15)",
                              marginBottom: "8px",
                            }}
                          >
                            There are no data yet
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    ) : (
                      <>
                        <Grid style={{ overflowY: "scroll", height: "230px" }}>
                          {hmeData !== null &&
                            hmeData !== undefined &&
                            hmeData.map((item, index) => (
                              <MDBox
                                key={`HME_${item.dealer}_${index + 1}`}
                                px="24px"
                                py="16px"
                                style={{ borderBottom: "2px solid #F0F2F5" }}
                              >
                                <Grid container>
                                  <Grid item pr="12px" md={6} xl={6}>
                                    <MDTypography style={{ color: "#1C1B1B" }}>
                                      {item.country === "HME"
                                        ? "HME"
                                        : getCountryDefaultValue(item.country)
                                            .label}
                                      {/* {item.country} */}
                                    </MDTypography>
                                  </Grid>
                                  {/* <Grid item pr="12px" md={6} xl={3}>
                                    <MDTypography style={{ color: "#1C1B1B" }}>
                                      {item.dealer}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={4.5}>
                                    <MDTypography style={{ color: "#1C1B1B" }}>
                                      {item.appId}
                                    </MDTypography>
                                  </Grid> */}
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.subscription}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.unsubscription}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.count}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {convertSecondToTime(item.averageTime)}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            ))}
                        </Grid>
                        <Grid style={{ backgroundColor: "#F6F3F2" }}>
                          {summaryHMEData !== null &&
                            summaryHMEData !== undefined && (
                              <MDBox
                                px="24px"
                                py="16px"
                                style={{
                                  height: "58px",
                                  borderBottom: "2px solid #F0F2F5",
                                }}
                              >
                                <Grid container>
                                  <Grid item pr="12px" md={6} xl={6}>
                                    <MDTypography
                                      fontWeight="bold"
                                      style={{ color: "#1C1B1B" }}
                                    >
                                      Total
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      fontWeight="bold"
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {summaryHMEData.subscription}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      fontWeight="bold"
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                      }}
                                    >
                                      {summaryHMEData.unsubscription}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      fontWeight="bold"
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                        height: "18px",
                                      }}
                                    >
                                      {summaryHMEData.summaryDate}
                                    </MDTypography>
                                    <MDTypography
                                      style={{
                                        textAlign: "center",
                                        color: "#7B809A",
                                        fontSize: "10px",
                                      }}
                                    >
                                      (Average)
                                    </MDTypography>
                                  </Grid>
                                  <Grid item pr="12px" md={6} xl={1.5}>
                                    <MDTypography
                                      fontWeight="bold"
                                      style={{
                                        color: "#1C1B1B",
                                        textAlign: "center",
                                        height: "18px",
                                      }}
                                    >
                                      {summaryHMEData.averageTime}
                                    </MDTypography>
                                    <MDTypography
                                      style={{
                                        textAlign: "center",
                                        color: "#7B809A",
                                        fontSize: "10px",
                                      }}
                                    >
                                      (Average)
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            )}
                        </Grid>
                      </>
                    )}
                  </Card>
                </Grid>
              )}
            </Grid>
            {/* {dealershipCountryList.includes(language) && filteredAppIDList && (
              <Grid mt="10px" container>
                <Grid item pr="12px" xl={12}>
                  <Card>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item pr="12px" md={6} xl={8}>
                            <MDTypography
                              // variant="b5"
                              style={{ color: "#7e7e7e", fontSize: "14px" }}
                            >
                              Subscribed Apps ({summaryDashboardData.subscribe})
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={4}>
                            <TextField
                              type="text"
                              label="Search"
                              placeholder="Search by Dealership code or App ID"
                              onChange={handleSearch}
                              fullWidth
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={searchSvg} alt="" />
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    <Grid>
                      <MDBox
                        px="24px"
                        py="16px"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "60px",
                          borderBottom: "2px solid #F0F2F5",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item pr="12px" md={6} xl={2}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                              }}
                            >
                              Dealership Code
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={5}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              App ID
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={3}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              Last execution
                            </MDTypography>
                          </Grid>
                          <Grid item pr="12px" md={6} xl={2}>
                            <MDTypography
                              // variant="b5"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              Subscription
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                    <Grid style={{ overflowY: "auto", height: "230px" }}>
                      {filteredAppIDList.length > 0 ? (
                        filteredAppIDList.map((item) => (
                          <MDBox
                            key={`${item.dealershipCode} - ${item.appId}`}
                            px="24px"
                            py="16px"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "60px",
                              borderBottom: "2px solid #F0F2F5",
                            }}
                          >
                            <Grid
                              container
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item pr="12px" md={6} xl={2}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.dealershipCode}
                                  </MDTypography>
                                </Grid>
                                <Grid item pr="12px" md={6} xl={5}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.appId}
                                  </MDTypography>
                                </Grid>
                                <Grid item pr="12px" md={6} xl={3}>
                                  <MDTypography
                                    // variant="b5"
                                    style={{
                                      color: "#344767",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.executeDate}
                                  </MDTypography>
                                </Grid>
                                <Grid
                                  item
                                  pr="12px"
                                  md={6}
                                  xl={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Switch
                                    value={item.appId}
                                    checked={item.subscription}
                                    color="blue"
                                    onClick={() => handleSwitch(item)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </MDBox>
                        ))
                      ) : (
                        <MDBox
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "20px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.4)",
                              marginBottom: "8px",
                            }}
                          >
                            No Data
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontFace: "Hyundai Sans Head",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "rgba(17, 17, 17, 0.15)",
                              marginBottom: "8px",
                            }}
                          >
                            There are no data yet
                          </MDTypography>
                        </MDBox>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            )} */}
            <MDBox style={{ marginTop: "24px" }}>
              <MDTypography variant="b7" color="darkGray">
                {hmeLogs.from !== undefined
                  ? `Number of subscribed apps : ${
                      month[parseInt(hmeLogs.from.substring(3, 5), 10) - 1]
                    }. ${hmeLogs.from.substring(6, 11)} ~ ${
                      month[parseInt(hmeLogs.to.substring(3, 5), 10) - 1]
                    }. ${hmeLogs.to.substring(6, 11)}`
                  : "Number of subscribed apps : "}
              </MDTypography>
              <Grid container mt="20px">
                <Grid item pr="12px" xl={12}>
                  <Card style={{ overflow: "hidden" }}>
                    <MDBox>
                      <Grid
                        container
                        style={{ borderBottom: "2px solid #F0F2F5" }}
                        alignItems="center"
                      >
                        {hmeLogs.result !== null &&
                          hmeLogs.result !== undefined &&
                          hmeLogs.result.map((log, index) => (
                            <Grid item pt="25px" pb="25px" xl={1}>
                              <MDTypography
                                align="center"
                                variant="b5"
                                style={{ color: "#7B809A" }}
                              >
                                {log >= 0 &&
                                  `${
                                    month[
                                      (parseInt(
                                        hmeLogs.from.substring(3, 5),
                                        10
                                      ) +
                                        (11 - index) -
                                        1) %
                                        12
                                    ]
                                  }.${
                                    parseInt(
                                      hmeLogs.from.substring(6, 11),
                                      10
                                    ) +
                                    (parseInt(
                                      hmeLogs.from.substring(3, 5),
                                      10
                                    ) +
                                      (11 - index) -
                                      1 >=
                                    12
                                      ? 1
                                      : 0)
                                  }`}
                              </MDTypography>
                            </Grid>
                          ))}
                      </Grid>
                    </MDBox>
                    <MDBox>
                      <Grid container>
                        {hmeLogs.result !== null &&
                          hmeLogs.result !== undefined &&
                          hmeLogs.result.map((log, index) => (
                            <Grid item pt="25px" pb="25px" xl={1}>
                              <MDTypography
                                align="center"
                                // color="darkGray"
                                // fontWeight="bold"
                              >
                                {`${hmeLogs.result[11 - index]}`}
                              </MDTypography>
                            </Grid>
                          ))}
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      {switching && (
        <MDBox
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "start",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: "9999",
          }}
        >
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              padding: "12px 10px",
              marginTop: "24px",
              marginRight: "24px",

              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <MDTypography>Loading...</MDTypography>
          </MDBox>
        </MDBox>
      )}
      {toggle && toggleAlertComponent()}
      {temporaryNotice && temporaryNoticeModal()}
    </DashboardLayout>
  );
}

export default ElectrifiedSetting;
