import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function Template2Aspect43({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  header,
  desc,
  url,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t2_aspect_4_3_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-aspect43-contents">
        <div className="template-aspect43-contents-left">
          <div>
            <div className="black h6 template-aspect43-contents-left-header">
              {header}
            </div>
            <div className="dark-gray h3 template-aspect43-contents-left-title">
              {title}
            </div>
            <div className="deep-gray b2 template-aspect43-contents-left-comment">
              {comment}
            </div>
          </div>
          <div className="deep-gray b6 template-aspect43-contents-left-desc">
            {desc}{" "}
            <span>
              {url !== undefined &&
                url !== "(website’s link for WLTP depending on market)" &&
                `${url === "" ? "" : `(${url})`}`}
            </span>
          </div>
        </div>
        <div className="template-aspect43-contents-right">
          <img src={`${GET_IMAGE_URL}${image}.png`} alt="" />
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

Template2Aspect43.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  header: "",
  desc: "",
  url: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

Template2Aspect43.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  header: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default Template2Aspect43;
