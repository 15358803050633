import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import rightvector from "assets/images/rightvector.png";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function ConnectivityPage({ subtitle, itemTitle, learn, setFlag }) {
  return (
    <NewWindow
      title="connectivity_main_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="connectivity">
        <div className="connectivity-items-content background-image">
          <img
            className="connectivity-background"
            src={`${GET_IMAGE_URL}Bluelink_main.png`}
            alt=""
          />
          <div className="connectivity-items-content-wrapper">
            <div>
              <div className="h3 connectivity-items-content-title">
                {subtitle[0]}
              </div>
              <div className="b3 connectivity-items-content-desc">
                {itemTitle[0]}
              </div>
            </div>
            <div className="white connectivity-items-content-more">
              {learn}
              <img className="more-white" src={rightvector} alt="" />
            </div>
          </div>
        </div>
        <div className="connectivity-items-content background-image">
          <img
            className="connectivity-background"
            src={`${GET_IMAGE_URL}chargemyHyundai_main.jpg`}
            alt=""
          />
          <div className="connectivity-items-content-wrapper">
            <div>
              <div className="dark-gray h3 connectivity-items-content-title">
                {subtitle[1]}
              </div>
              <div className="dark-gray b3 connectivity-items-content-desc">
                {itemTitle[1]}
              </div>
            </div>
            <div className="primary-blue connectivity-items-content-more">
              {learn}
              <img className="more-primary-blue" src={rightvector} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Nav electrified={subtitle} pageClass={itemTitle} />
    </NewWindow>
  );
}

ConnectivityPage.defaultProps = {
  subtitle: "",
  itemTitle: "",
  learn: "",
  setFlag: () => {},
};

ConnectivityPage.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  learn: PropTypes.string,
  setFlag: PropTypes.string,
};

export default ConnectivityPage;
