import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import rightvector from "assets/images/rightvector.png";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function PowertrainPage({ subtitle, itemTitle, learn, setFlag }) {
  return (
    <NewWindow
      title="powertrain_main_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="powertrain">
        <div className="bg-white powertrain-items-content-2x">
          <div className="powertrain-items-content-text-wrapper">
            <div>
              <div className="h3 dark-gray powertrain-items-content-title">
                {subtitle[0]}
              </div>
              <div className="b2 dark-gray powertrain-items-content-desc">
                {itemTitle[0]}
              </div>
            </div>
            <div className="primary-blue powertrain-items-content-more">
              {learn}
              <img className="more-primary-blue" src={rightvector} alt="" />
            </div>
          </div>
        </div>
        <div className="bg-light-sand powertrain-items-content">
          <div className="powertrain-items-content-text-wrapper">
            <div>
              <div className="h3 dark-gray powertrain-items-content-title">
                {subtitle[1]}
              </div>
              <div className="b2 dark-gray powertrain-items-content-desc">
                {itemTitle[1]}
              </div>
            </div>
            <div>
              <div className="primary-blue powertrain-items-content-more">
                {learn}
                <img className="more-primary-blue" src={rightvector} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light-sand powertrain-items-content">
          <div className="powertrain-items-content-text-wrapper">
            <div>
              <div className="h3 dark-gray powertrain-items-content-title">
                {subtitle[2]}
              </div>
              <div className="b2 dark-gray powertrain-items-content-desc">
                {itemTitle[2]}
              </div>
            </div>
            <div>
              <div className="primary-blue powertrain-items-content-more">
                {learn}
                <img className="more-primary-blue" src={rightvector} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light-sand powertrain-items-content">
          <div className="powertrain-items-content-text-wrapper">
            <div>
              <div className="h3 dark-gray powertrain-items-content-title">
                {subtitle[3]}
              </div>
              <div className="b2 dark-gray powertrain-items-content-desc">
                {itemTitle[3]}
              </div>
            </div>
            <div>
              <div className="primary-blue powertrain-items-content-more">
                {learn}
                <img className="more-primary-blue" src={rightvector} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white powertrain-items-content">
          <div className="powertrain-items-content-text-wrapper">
            <div>
              <div className="h3 dark-gray powertrain-items-content-title">
                {subtitle[4]}
              </div>
              <div className="b2 dark-gray powertrain-items-content-desc">
                {itemTitle[4]}
              </div>
            </div>
            <div>
              <div className="primary-blue powertrain-items-content-more">
                {learn}
                <img className="more-primary-blue" src={rightvector} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Nav electrified={subtitle} pageClass={itemTitle} />
    </NewWindow>
  );
}

PowertrainPage.defaultProps = {
  subtitle: "",
  itemTitle: "",
  learn: "",
  setFlag: () => {},
};

PowertrainPage.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  learn: PropTypes.string,
  setFlag: PropTypes.string,
};

export default PowertrainPage;
