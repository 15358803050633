import { useEffect, useState } from "react";
import { getCookie } from "utils/functions/cookie";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDButton from "components/MDButton";

import { getCountry } from "utils/functions/country";
import {
  // displaySetup,
  // saveEelctrifiedSettting,
  getDisplayVehicle,
  editDisplayVehicle,
} from "utils/functions/axios";
import { GET_IMAGE_URL } from "utils/constants";

import useLanguageStore from "utils/stores/language.store";
import useDealerStore from "utils/stores/dealer.store";
import CountrySelectorCard from "../3000_Translations/components/CountrySelectCard";

function ElectrifiedSetting() {
  // variable : //
  // const [electrifiedAll, setElectrifiedAll] = useState([]);
  // const [displayableElectrifies, setDisplayableElectrifies] = useState([]);
  // const [electrifiedVersion, setElectrifiedVersion] = useState(0);
  const [rerender, setRerender] = useState(false);
  const [countryInfo, setCountryInfo] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  // const [powertrainList, setPowertrainList] = useState([]);
  const [connectivityList, setConnectivityList] = useState([]);
  const [displayVersion, setDisplayVersion] = useState("");
  const [displayAlert, setDisplayAlert] = useState(false);

  const [init, setInit] = useState(false);
  const [saveStatus, setSaveStatus] = useState("SAVED");
  const [switching, setSwitching] = useState(false);
  const { language, change, setChange } = useLanguageStore();
  const { dealer } = useDealerStore();

  // function: //
  // description: //
  // const onSwitchHandler = (item) => {
  //   const idx = displayableElectrifies.indexOf(item);
  //   const arr = displayableElectrifies;
  //   if (idx === -1) {
  //     arr.push(item);
  //     setDisplayableElectrifies(arr);
  //   } else {
  //     arr.splice(idx, 1);
  //     setDisplayableElectrifies(arr);
  //   }
  //   setRerender(!rerender);
  // };

  // const onSaveHandler = async () => {
  //   const display = await saveEelctrifiedSettting(
  //     displayableElectrifies,
  //     electrifiedVersion
  //   );
  //   if (display.result) {
  //     setDisplayableElectrifies(display.displayableElectrifies);
  //     setElectrifiedVersion(display.electrified_version);
  //   }
  //   setRerender(!rerender);
  // };

  const handleVehicleSwitch = (e, index) => {
    const list = vehicleList;

    list[index].display = e.target.checked;

    setVehicleList(list);
    setSaveStatus("SAVE");
    setRerender(!rerender);
  };
  const handleConnectivityChange = (e, index) => {
    const list = connectivityList;

    list[index].display = e.target.checked;

    setConnectivityList(list);
    setSaveStatus("SAVE");
    setRerender(!rerender);
  };

  const handleSave = async () => {
    let newVersion = parseFloat(displayVersion);
    newVersion += 0.1;
    newVersion = newVersion.toFixed(1);

    const countryCookie = getCookie("country");
    const { country: dealerCountry } = dealer;

    const nation = countryCookie !== undefined ? countryCookie : dealerCountry;
    const country = language === "" ? nation : language;

    const body = {
      // country: localStorage.getItem("country"),
      country,
      version: newVersion,
      vehicle: vehicleList,
      // powertrain: powertrainList,
      connectivity: connectivityList,
    };

    await editDisplayVehicle(body).then(() => setSaveStatus("SAVED"));

    setDisplayVersion(newVersion.toString());
    setRerender(!rerender);
  };

  useEffect(() => {
    if (!change && init) return;

    const setDisplay = async () => {
      // console.log(language);
      if (language === "HME" || language === "") {
        setSwitching(true);

        setVehicleList([]);
        // setPowertrainList([]);
        setConnectivityList([]);
        setDisplayVersion("");

        setSwitching(false);

        return;
      }

      setSwitching(true);

      const countryCookie = getCookie("country");
      const { country: dealerCountry } = dealer;

      const nation =
        countryCookie !== undefined ? countryCookie : dealerCountry;

      const country = language === "" ? nation : language;

      const vehicle = await getDisplayVehicle(country);

      if (vehicle.result) {
        setVehicleList(vehicle.data.data.vehicle);
        // setPowertrainList(vehicle.data.data.powertrain);
        setConnectivityList(vehicle.data.data.connectivity);
        setDisplayVersion(vehicle.data.data.version);
      }

      setSwitching(false);
    };

    const country = getCountry(getCookie("country"));
    setCountryInfo(country);

    setDisplay();
    setChange(false);
    setInit(true);
  }, [change]);

  // component: //
  // function electrifiedCard(electrified) {
  //   return (
  //     <Grid key={electrified.electrified_item_name} item xs={12} md={6} lg={3}>
  //       <MDBox mb={3} mt={8}>
  //         <Card style={{ backgroundColor: "#F6F3F2" }}>
  //           <MDBox padding="1rem" textAlign="center">
  //             <MDBox
  //               component="img"
  //               src={`${GET_IMAGE_URL}${electrified.main_image}`}
  //               borderRadius="lg"
  //               py={2}
  //               mt={-12}
  //               height="8rem"
  //             />
  //           </MDBox>
  //           <MDTypography
  //             px={1}
  //             color="darkGray"
  //             lineHeight={1}
  //             sx={{ mx: 3 }}
  //             style={{ fontWeight: 500 }}
  //           >
  //             {electrified.electrified_item_name}
  //           </MDTypography>
  //           <Divider />
  //           <MDBox px={2} mb={2}>
  //             <Switch
  //               value={electrified.electrified_item_name}
  //               checked={
  //                 displayableElectrifies.indexOf(
  //                   electrified.electrified_item_name
  //                 ) !== -1
  //               }
  //               color="blue"
  //               onClick={() =>
  //                 onSwitchHandler(electrified.electrified_item_name)
  //               }
  //             />
  //           </MDBox>
  //         </Card>
  //       </MDBox>
  //     </Grid>
  //   );
  // }

  function errorSaveAlertComponent() {
    return (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              Failed to save the display status
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              Please select at least one vehicle model to be displayed.
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={() => {
                setDisplayAlert(false);
              }}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function vehicleCard(name, vehicle, index) {
    return (
      <Grid key={vehicle.name} item xs={12} md={6} lg={3}>
        <MDBox mb={3} mt={8}>
          <Card style={{ backgroundColor: "#F6F3F2" }}>
            <MDBox padding="1rem" textAlign="center">
              <MDBox
                component="img"
                src={`${GET_IMAGE_URL}${vehicle.image}`}
                borderRadius="lg"
                py={2}
                mt={-12}
                height="8rem"
              />
            </MDBox>
            <MDTypography
              px={1}
              variant="medium"
              color="darkGray"
              textTransform="uppercase"
              lineHeight={1}
              fontSize="18px"
              sx={{ mx: 3 }}
              style={{ fontWeight: "bold" }}
            >
              {vehicle.name}
            </MDTypography>
            {/* <Divider
              light
              style={{
                marginTop: "7px",
              }}
            /> */}
            <MDBox
              fullWidth
              style={{
                height: "1px",
                marginTop: "10px",
                marginLeft: "16px",
                marginRight: "16px",
                backgroundColor: "#DDDDDD",
              }}
            >
              {}
            </MDBox>
            <MDBox px={2} mb={2}>
              <Switch
                value={vehicle.name}
                checked={vehicle.display}
                color="blue"
                onClick={(e) =>
                  name === "Electrified"
                    ? handleVehicleSwitch(e, index)
                    : handleConnectivityChange(e, index)
                }
              />
            </MDBox>
          </Card>
        </MDBox>
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={-4} py={1}>
        <MDBox mt={2}>
          <Grid container spacing={1.5}>
            <CountrySelectorCard />
          </Grid>
        </MDBox>
        <MDBox style={{ marginTop: "24px" }}>
          <Card>
            <MDBox style={{ padding: "24px" }}>
              <Grid container space={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <MDBox style={{ display: "flex", itemAligns: "center" }}>
                    {countryInfo && (
                      <MDTypography
                        variant="body1"
                        color="darkGray"
                        lineHeight={1}
                        style={{
                          verticalAlign: "middle",
                          color: "#1C1B1B",
                          fontWeight: "bold",
                          fontSize: "24px",
                        }}
                      >
                        Menu Setup{"  "}
                        <span style={{ color: "#00AAD2" }}>
                          v {displayVersion}
                        </span>
                        {/* Setup Display Vehicle -{" "}
                        <span style={{ color: "#00AAD2" }}>
                          v {electrifiedVersion}
                        </span> */}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <MDButton
                    style={{
                      float: "right",
                      backgroundColor: "#002C5f",
                      color: "white",
                      width: "110px",
                    }}
                    // variant="contained"
                    // color="#002C5F"
                    onClick={() => {
                      let flag = false;
                      for (let i = 0; i < vehicleList.length; i += 1) {
                        if (vehicleList[i].display) {
                          flag = true;
                          break;
                        }
                      }

                      if (!flag) {
                        // Popup Alert
                        setDisplayAlert(true);
                        return;
                      }

                      setSaveStatus("SAVING...");
                      handleSave();
                    }}
                    disabled={saveStatus !== "SAVE"}
                    // size="small"
                  >
                    {saveStatus}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <Divider light />
            <MDBox px={3}>
              <MDTypography
                style={{
                  fontSize: "20px",
                  marginTop: "20px",
                  marginBottom: "35px",
                  color: "#111111",
                  fontWeight: "bold",
                }}
              >
                Vehicle
              </MDTypography>
              <Grid container spacing={4}>
                {vehicleList &&
                  vehicleList.map((vehicle, index) =>
                    vehicleCard("Electrified", vehicle, index)
                  )}
              </Grid>
              <Divider style={{ marginTop: "15px" }} light />
              <MDTypography
                style={{
                  marginTop: "20px",
                  marginBottom: "35px",
                  fontSize: "20px",
                  color: "#111111",
                  fontWeight: "bold",
                }}
              >
                Contents
              </MDTypography>
              <Grid container spacing={4}>
                {connectivityList &&
                  connectivityList.map((vehicle, index) =>
                    vehicleCard("Connectivity", vehicle, index)
                  )}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      {switching && (
        <MDBox
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "start",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: "9999",
          }}
        >
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              padding: "12px 10px",
              marginTop: "24px",
              marginRight: "24px",

              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <MDTypography>Loading...</MDTypography>
          </MDBox>
        </MDBox>
      )}
      {displayAlert && errorSaveAlertComponent()}
    </DashboardLayout>
  );
}

export default ElectrifiedSetting;
