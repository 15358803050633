// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// import PlaceholderImage from "assets/images/placeholder.png";

import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { GET_IMAGE_URL } from "utils/constants";
// import { getTranslationItem } from "utils/functions/axios";
import { getLanguageName } from "utils/functions/language";

const noTextTemplate = ["T1_Full", "T1_Half", "T3_Video"];

function templateItemCard(
  itemTitle,
  templateTitle,
  pageIndex,
  itemIndex,
  originalItems,
  translatedItems,
  language,
  countryCode,
  setItemTitle,
  setTemplateTitle,
  setSelectedTemplateData,
  setSelectedOriginalTemplateData,
  setHandler,
  setTemplatePopup
) {
  // description: translation handler //
  const onEditHandler = () => {
    setItemTitle(itemTitle, templateTitle);
    setTemplateTitle(templateTitle);
    setSelectedOriginalTemplateData(
      originalItems.pages[pageIndex].items[itemIndex].template_data
    );
    setSelectedTemplateData(
      translatedItems.pages[pageIndex].items[itemIndex].template_data
    );
    setHandler(translatedItems.pages[pageIndex].items[itemIndex].template_data);
  };

  // description: preview handler //
  // const onPreviewHandler = () => {
  //   if (item.type === "Template 1") {
  //     let pageLength = 0;
  //     if (menu === "Highlights") pageLength = translations.highlights.length;
  //     if (menu === "Charging") pageLength = translations.charging.length;
  //     if (menu === "Benefits") pageLength = translations.benefits.length;
  //     const popupItem = {
  //       title: item.title,
  //       comment: item.comment,
  //       description: item.description ? item.description : "none",
  //       pageClass: menu,
  //       seq: item.sequence_number,
  //       pageLength,
  //       image: item.image,
  //       electrified: group,
  //     };
  //     setTemplate1PopItem(popupItem);
  //   }
  //   // if (item.type === "Template 2") {
  //   // }
  //   // if (item.type === "Template 3") {
  //   // }
  //   setTemplate1Pop(true);
  // };

  const onPreviewHandler = () => {
    // setTemplateTitle(templateTitle);
    setTemplatePopup(
      "translated",
      translatedItems.pages[pageIndex].items[itemIndex]
    );
  };

  function ImageComponent() {
    let imageSrc = "";

    if (
      countryCode === "PT" &&
      translatedItems.page_subtitle === "KONA Electric"
    ) {
      imageSrc =
        translatedItems.pages[pageIndex].items[itemIndex].template_data.image
          .length > 0
          ? `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.image[0]}_portu.png`
          : `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.video[0]}_portu.png`;
    } else if (
      translatedItems.page_subtitle === "IONIQ 6" &&
      templateTitle === "T2_16-9_Horizontal"
    ) {
      imageSrc = `${GET_IMAGE_URL}IONIQ6_04_thumbnail.png`;
    } else if (
      countryCode === "DE" &&
      translatedItems.page_subtitle === "Mild Hybrid" &&
      (itemIndex === 1 || itemIndex === 2)
    ) {
      imageSrc =
        translatedItems.pages[pageIndex].items[itemIndex].template_data.image
          .length > 0
          ? `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.image[0]}_DE.png`
          : `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.video[0]}_DE.png`;
    } else {
      imageSrc =
        translatedItems.pages[pageIndex].items[itemIndex].template_data.image
          .length > 0
          ? `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.image[0]}.png`
          : `${GET_IMAGE_URL}${translatedItems.pages[pageIndex].items[itemIndex].template_data.video[0]}.png`;
    }

    return (
      <MDBox
        component="img"
        src={imageSrc}
        style={{
          width: "200px",
          height: "160px",
          objectFit: "cover",
        }}
      />
    );
  }

  return (
    <MDBox my={1} mx={1.5} style={{ display: "inline-block", width: "240px" }}>
      <Card style={{ backgroundColor: "#F6F3F2", boxShadow: "none" }}>
        <MDBox p={2}>
          <MDBox textAlign="center">
            <ImageComponent />
          </MDBox>
          <MDTypography
            mt="16px"
            color="darkGray"
            style={{
              fontFamily: "Hyundai Sans Head Medium",
              fontSize: "16px",
            }}
          >
            {`${itemTitle} #${
              originalItems.pages[pageIndex].items[itemIndex].template_data
                .content_sequence + 1
            }`}
          </MDTypography>
          <MDBox mt="6px">
            {noTextTemplate.includes(templateTitle) ? (
              <MDTypography variant="b7" color="text" opacity={0.6}>
                No Text
              </MDTypography>
            ) : null}
            {!noTextTemplate.includes(templateTitle) && (
              <MDTypography
                variant="b7"
                color="text"
                opacity={0.6}
                textTransform="uppercase"
              >
                {getLanguageName(language)}
              </MDTypography>
            )}
            {}
          </MDBox>
          <MDBox mt="19px">
            <MDButton
              color="blue"
              fullWidth
              // disabled={item.type === "Template 3"}
              disabled={noTextTemplate.includes(templateTitle)}
              onClick={() => onEditHandler()}
            >
              <MDTypography variant="b7" color="white">
                EDIT
              </MDTypography>
            </MDButton>
          </MDBox>
          <MDBox mt="16px">
            <MDButton
              style={{ backgroundColor: "#E4DCD3" }}
              fullWidth
              onClick={onPreviewHandler}
            >
              <MDTypography variant="b7" color="brown">
                PREVIEW
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default templateItemCard;
