import { Card, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import MDButton from "components/MDButton";

// css
import "./index.css";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLayoutEffect, useState } from "react";
import {
  DOWNLOAD_WINDOWS_TEXT,
  // DOWNLOAD_MAC_TEXT,
  // CONNECTIVTY,
  EVSHOWROOM,
  // POWERTRAIN,
  // ELECTRIFIED,
  GET_RELEASE_URL,
} from "utils/constants";
import {
  // getLatestRelease,
  getDealerData,
  setSubscription,
  deleteSubscription,
} from "utils/functions/axios";
import useLanguageStore from "utils/stores/language.store";
import useDealerStore from "utils/stores/dealer.store";
import { getDownloadIcon } from "utils/functions/download";
import { getCookie } from "utils/functions/cookie";

// icon
import activeDelte from "assets/images/active-delete.png";
import inActiveDelte from "assets/images/inactive-delete.png";

const subscribeTitle = "Successfully subscribed";
const subscribeComment =
  "A subscription has been added. Please restart the app to activate your subscription. Checking the subscription status may take up to 5 minutes after the app is started.";

const unsubscribeTitle = "Successfully unsubscribed";
const unsubscribeComment = "A subscription has been removed.";

const subscribeCheckTitle = "Billing Information";
const subscribeCheckComment =
  "If you subscribe the app, a subscription fee for full month will be charged on upcoming 15th. Do you want to add the subscription?";

const unsubscribeCheckTitle = "Unsubscribe";
const unsubscribeCheckComment = "Do you want to unsubscribe the app?";

const errorTitle = "Please try again";
const errorComment = "Failed to save the subscription status. Please try again";

const deleteTitle = "Remove App ID";
const deleteComment = "Do you want to remove the App ID?";

const deletedSuccessTitle = "Successfully removed";
const deletedSuccessComment = "App ID has been successfully removed.";

const deleteErrorComment = "Failed to remove the App ID. Please try again.";

function ElectrifiedSetting() {
  // variable : //

  // const [windowsURL, setWindowsURL] = useState("");
  // const [macURL, setMacURL] = useState("");

  const [devices, setDevices] = useState([]);
  const [deviceCount, setDeviceCount] = useState(0);
  const [countAlert, setCountAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [deleteAlertTitle, setDeleteAlertTitle] = useState("");
  const [alertComment, setAlertComment] = useState("");
  const [deleteAlertComment, setDeleteAlertComment] = useState("");
  const [countStatus, setCountStatus] = useState({});
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertResult, setAlertResult] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState();
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const { dealer } = useDealerStore();
  const { temporaryNotice, setTemporaryNotice } = useLanguageStore();

  const getSummaryQuantities = (list) => {
    let count = 0;

    for (let i = 0; i < list.length; i += 1) {
      count += list[i].quantity;
    }

    return count;
  };

  const handleCount = (count, index) => {
    // if (!devices[index].register) return;
    // if (devices[index].quantity + count < 0) return;

    // const list = JSON.parse(JSON.stringify(devices));

    // list[index].quantity += count;

    // setDevices(list);

    setCountStatus({
      count: devices[index].quantity > 0 ? 0 : 1,
      i: index,
    });

    setAlertTitle(
      devices[index].quantity <= 0 ? subscribeCheckTitle : unsubscribeCheckTitle
    );
    setAlertComment(
      devices[index].quantity <= 0
        ? subscribeCheckComment
        : unsubscribeCheckComment
    );

    setAlertResult(false);
    setCountAlert(true);
  };

  const getDateString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    let stringTime = "";

    stringTime += year.toString();
    stringTime += month >= 10 ? month : `0${month}`;
    stringTime += day >= 10 ? day : `0${day}`;

    stringTime += " ";

    stringTime += `${hour >= 10 ? `${hour}` : `0${hour}`}:`;
    stringTime += `${minute >= 10 ? `${minute}` : `0${minute}`}:`;
    stringTime += second >= 10 ? `${second}` : `0${second}`;

    return stringTime;
  };

  const handleSubmit = async () => {
    const { count, i } = countStatus;

    if (!loading) {
      const list = JSON.parse(JSON.stringify(devices));

      list[i].quantity = count;

      const dateString = getDateString(new Date());

      if (devices[i].quantity === 0 && count === 1)
        list[i].subscribe = dateString;

      const log = {
        appId: list[i].appId,
        date: dateString,
        register: count > 0,
      };

      setLoading(true);

      await setSubscription(list, log).then((res) => {
        if (res.result) {
          setAlertTitle(
            count > devices[i].quantity ? subscribeTitle : unsubscribeTitle
          );
          setAlertComment(
            count > devices[i].quantity ? subscribeComment : unsubscribeComment
          );

          const cnt = getSummaryQuantities(list);

          setDeviceCount(cnt);
          setDevices(list);
        } else {
          setAlertTitle(errorTitle);
          setAlertComment(errorComment);
        }

        setAlertResult(true);
        setRerender(!rerender);
        setLoading(false);
      });
    } else {
      setAlertTitle(
        count > devices[i].quantity ? subscribeTitle : unsubscribeTitle
      );
      setAlertComment(
        count > devices[i].quantity ? subscribeComment : unsubscribeComment
      );
    }
  };

  const handleDelete = async () => {
    setDeleteProcessing(true);
    setLoading(true);

    await deleteSubscription(dealer.id, deleteTarget)
      .then((res) => {
        if (res.result) {
          setDeleteAlertTitle(deletedSuccessTitle);
          setDeleteAlertComment(deletedSuccessComment);
        } else {
          setDeleteAlertTitle(errorTitle);
          setDeleteAlertComment(deleteErrorComment);
        }
      })
      .catch((e) => {
        console.error(e);
        setDeleteAlertTitle(errorTitle);
        setDeleteAlertComment(deleteErrorComment);
      })
      .finally(async () => {
        const dealerData = await getDealerData(dealer.id);

        const list = dealerData.data.app_id
          ? dealerData.data.app_id.sort((a, b) => {
              if (a.quantity > b.quantity) {
                return -1;
              }
              if (a.appId > b.quantity) {
                return 1;
              }
              return 2;
            })
          : [];

        const count = getSummaryQuantities(list);

        setDevices(list);
        setDeviceCount(count);
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setCountAlert(false);
  };

  useLayoutEffect(() => {
    const getReleaseURL = async () => {
      // const windowsRelease = await getLatestRelease("windows");
      // const macRelease = await getLatestRelease("darwin");

      const { id } = dealer;
      const idCookie = getCookie("userId");

      const userId = idCookie !== undefined ? idCookie : id;

      const dealerData = await getDealerData(userId);

      const list = dealerData.data.app_id
        ? dealerData.data.app_id.sort((a, b) => {
            if (a.quantity > b.quantity) {
              return -1;
            }
            if (a.appId > b.quantity) {
              return 1;
            }
            return 2;
          })
        : [];

      const count = getSummaryQuantities(list);

      // setWindowsURL(windowsRelease.url);
      // setWindowsURL(
      //   "https://drive.google.com/file/d/1k5DdzsByzmX2of8gb-eOcxFSzX0le0xQ/view?usp=drive_link"
      // );
      // console.log(windowsURL);
      // setMacURL(macRelease.url);

      setDevices(list);
      setDeviceCount(count);
    };

    getReleaseURL();
  }, []);

  // function: //
  // description: //

  // component: //
  function downloadItem(type) {
    return (
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <MDBox mb="24px">
          <MDBox
            width="180px"
            height="180px"
            component="img"
            src={getDownloadIcon(type)}
            style={{
              borderRadius: "24px",
            }}
          />
          <MDTypography mt="28px" variant="b8" color="darkGray">
            {type}
          </MDTypography>
          <MDTypography mt="16px" variant="b7">
            <a
              href={GET_RELEASE_URL}
              style={{
                color: "#00AAD2",
              }}
            >
              {DOWNLOAD_WINDOWS_TEXT}
            </a>
          </MDTypography>
          {/* <MDTypography mt="16px" variant="b7">
            <a
              href={macURL}
              style={{
                color: "#00AAD2",
              }}
            >
              {DOWNLOAD_MAC_TEXT}
            </a>
          </MDTypography> */}
        </MDBox>
      </MDBox>
    );
  }

  // function downloadView() {
  //   return (
  //     <MDBox mt={-2} py={1}>
  //       <Card>
  //         <MDBox p="24px">
  //           <MDTypography
  //             variant="h6"
  //             color="darkGray"
  //             style={{
  //               color: "#1C1B1B",
  //               fontWeight: "bold",
  //               fontSize: "24px",
  //             }}
  //           >
  //             Download
  //           </MDTypography>
  //           <Grid container mt="24px">
  //             {downloadItem(EVSHOWROOM)}
  //           </Grid>
  //         </MDBox>
  //       </Card>
  //     </MDBox>
  //   );
  // }

  function downloadAndEditView() {
    return (
      <MDBox>
        <Grid container>
          <Grid item pr="12px" xl={3}>
            <Card p="24px">
              <MDBox p="24px" style={{ overflowY: "hidden", height: "420px" }}>
                <MDTypography
                  variant="h6"
                  color="darkGray"
                  style={{
                    color: "#1C1B1B",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                >
                  Download
                </MDTypography>
                <MDBox mt="24px">{downloadItem(EVSHOWROOM)}</MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item pr="12px" xl={9}>
            <Card style={{ overflow: "hidden", height: "420px" }}>
              <MDBox p="24px">
                <MDBox
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <MDTypography
                    variant="h6"
                    color="darkGray"
                    style={{
                      color: "#1C1B1B",
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    App Subscription ({deviceCount})
                  </MDTypography>
                </MDBox>
                <MDBox container mt="24px">
                  <Grid container>
                    {/* <Grid item xl={1}>
                      <MDTypography variant="b5" style={{ color: "#7B809A" }}>
                        Subscription
                      </MDTypography>
                    </Grid> */}
                    <Grid item xl={6.5}>
                      <MDTypography variant="b5" style={{ color: "#7B809A" }}>
                        App ID
                      </MDTypography>
                    </Grid>
                    <Grid item xl={2}>
                      <MDTypography
                        variant="b5"
                        style={{ color: "#7B809A", textAlign: "center" }}
                      >
                        App Version
                      </MDTypography>
                    </Grid>
                    <Grid item xl={2}>
                      <MDTypography
                        variant="b5"
                        style={{ color: "#7B809A", textAlign: "center" }}
                      >
                        Quantities
                      </MDTypography>
                    </Grid>
                    <Grid item xl={1.5}>
                      <MDTypography
                        variant="b5"
                        style={{ color: "#7B809A", textAlign: "center" }}
                      >
                        Remove
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid style={{ overflowY: "auto", height: "300px" }}>
                    {devices !== undefined &&
                      devices.map((device, index) => (
                        <MDBox
                          key={device.appId}
                          style={{
                            borderBottom: "2px solid #F0F2F5",
                            paddingTop: "16px",
                          }}
                        >
                          <Grid
                            container
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* <Grid item xl={1}>
                              <MDTypography style={{ color: "#1C1B1B" }}>
                                <Switch
                                  value={device.appId}
                                  checked={device.register}
                                  color="blue"
                                  onClick={(e) => handleSwitch(e, index)}
                                />
                              </MDTypography>
                            </Grid> */}
                            <Grid item xl={6.5}>
                              <MDTypography
                                style={{
                                  color:
                                    device.quantity !== 0
                                      ? "#344767"
                                      : "#7F7F7E",
                                }}
                              >
                                {device.appId}
                              </MDTypography>
                            </Grid>
                            <Grid item xl={2}>
                              <MDTypography
                                style={{
                                  textAlign: "center",
                                  color:
                                    device.quantity !== 0
                                      ? "#344767"
                                      : "#7F7F7E",
                                }}
                              >
                                {device.appVersion || "No Data"}
                              </MDTypography>
                            </Grid>
                            <Grid
                              item
                              xl={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Switch
                                value={device.appId}
                                checked={device.quantity > 0}
                                color="blue"
                                onClick={() => handleCount(0, index)}
                              />
                              {/* <MDBox
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    device.quantity > 0 ? "#00aad2" : "#dddddd",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => handleCount(-1, index)}
                              >
                                <MDTypography
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  -
                                </MDTypography>
                              </MDBox>
                              <MDTypography
                                px="10px"
                                style={{
                                  width: "60px",
                                  color: "#1C1B1B",
                                  textAlign: "center",
                                }}
                              >
                                {device.quantity}
                              </MDTypography>
                              <MDBox
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  backgroundColor: "#00aad2",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => handleCount(1, index)}
                              >
                                <MDTypography
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  +
                                </MDTypography>
                              </MDBox> */}
                            </Grid>
                            <Grid
                              item
                              xl={1.5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <MDBox
                                alignItems="center"
                                display="flex"
                                justifyContent="center"
                                onClick={() => {
                                  if (device.quantity > 0) return;
                                  setDeleteAlertTitle(deleteTitle);
                                  setDeleteAlertComment(deleteComment);
                                  setDeleteTarget(device.appId);
                                  setDeleteAlert(!deleteAlert);
                                }}
                              >
                                {device.quantity === 0 ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                    }}
                                    className="hover-cursor"
                                    src={activeDelte}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "20px",
                                    }}
                                    src={inActiveDelte}
                                    alt=""
                                  />
                                )}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      ))}
                    {devices.length === 0 && (
                      <MDBox
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <MDTypography
                          style={{
                            fontFace: "Hyundai Sans Head",
                            fontSize: "20px",
                            fontWeight: "400",
                            color: "rgba(17, 17, 17, 0.4)",
                            marginBottom: "8px",
                          }}
                        >
                          No Subscriptions
                        </MDTypography>
                        <MDTypography
                          style={{
                            fontFace: "Hyundai Sans Head",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "rgba(17, 17, 17, 0.15)",
                            marginBottom: "8px",
                          }}
                        >
                          There are no subscriptions yet
                        </MDTypography>
                      </MDBox>
                    )}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  function deleteAlertComponent() {
    return loading ? (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "130px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography variant="b7">Saving...</MDTypography>
        </Card>
      </MDBox>
    ) : (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              {deleteAlertTitle}
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              {deleteAlertComment}
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            {!deleteProcessing && (
              <MDButton
                style={{
                  float: "right",
                  color: "rgba(0,0,0,0.4)",
                  width: "110px",
                }}
                onClick={() => setDeleteAlert(!deleteAlert)}
              >
                Cancel
              </MDButton>
            )}
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={() => {
                if (!deleteProcessing) {
                  handleDelete();
                } else {
                  setDeleteAlert(!deleteAlert);
                  setDeleteProcessing(false);
                }
              }}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function toggleAlertComponent() {
    return loading ? (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "130px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography variant="b7">Saving...</MDTypography>
        </Card>
      </MDBox>
    ) : (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              {alertTitle}
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              {alertComment}
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            {!alertResult && (
              <MDButton
                style={{
                  float: "right",
                  color: "rgba(0,0,0,0.4)",
                  width: "110px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </MDButton>
            )}
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={alertResult ? handleCancel : handleSubmit}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  const temporaryNoticeModalTitle = "This is a temporary page";
  const temporaryNoticeModalComment =
    "This is a temporary page for testing purpose only. Please access the platform and install the app via Dealer Portal.";
  function temporaryNoticeModal() {
    return (
      <MDBox
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              {temporaryNoticeModalTitle}
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              {temporaryNoticeModalComment}
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={() => {
                setTemporaryNotice(false);
              }}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {downloadAndEditView()}
      {countAlert && toggleAlertComponent()}
      {deleteAlert && deleteAlertComponent()}
      {temporaryNotice && temporaryNoticeModal()}
    </DashboardLayout>
  );
}

export default ElectrifiedSetting;
