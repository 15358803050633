import PropTypes from "prop-types";
import NewWindow from "react-new-window";

// import rightvector from "assets/images/RightArrow.png";
import Logo from "assets/images/Logo2.png";

import "./style.css";
import "../common.css";

function BluelinkNavPage({ pageItem, pageTitle, setFlag }) {
  return (
    <NewWindow
      title="bluelink_nav_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <nav className="connectivity-nav">
        <span className="connectivity-nav-left">
          <img className="connectivity-nav-logo-icon" src={Logo} alt="" />
          <div className="contents-nav-left-divider"> </div>
          <div className="h6">{pageTitle}</div>
        </span>
        <div className="bluelink-buttons">
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[0]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[1]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[2]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[3]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[4]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[5]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[6]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[7]}
          </div>
          <div className="h6 bluelink-button margin-right-button">
            {pageItem[8]}
          </div>
          <div className="h6 bluelink-button">{pageItem[9]}</div>
        </div>
      </nav>
    </NewWindow>
  );
}

BluelinkNavPage.defaultProps = {
  pageItem: "",
  pageTitle: "",
  setFlag: () => {},
};

BluelinkNavPage.propTypes = {
  pageItem: PropTypes.string,
  pageTitle: PropTypes.string,
  setFlag: PropTypes.string,
};

export default BluelinkNavPage;
