import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateT1Horizontal({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t1_horizontal_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-horizontal-contents">
        <div className="template-horizontal-contents-top">
          <div className="h3 template-horizontal-contents-top-title">
            {title}
          </div>
          <div className="b2 template-horizontal-contents-top-commnet">
            {comment}
          </div>
        </div>
        <div className="template-horizontal-contents-bottom">
          <img src={`${GET_IMAGE_URL}${image}.png`} alt="" />
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateT1Horizontal.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

TemplateT1Horizontal.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default TemplateT1Horizontal;
