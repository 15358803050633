import PropTypes from "prop-types";

import Logo from "assets/images/Logo2.png";
import CalcIcon from "assets/images/calc.png";

import "./style.css";

function ElectrifiedNav({ pageTitle, buttons }) {
  return (
    <nav className="electrified-nav">
      <div className="electrified-nav-left">
        <img className="electrified-nav-logo-icon" src={Logo} alt="" />
        <div className="contents-nav-left-divider"> </div>
        <div className="h6">{pageTitle}</div>
      </div>
      {buttons.length > 0 && (
        <div className="electrified-nav-center">
          <div className="h6 electrified-nav-center-item">{buttons[0]}</div>
          <div className="h6 electrified-nav-center-item">{buttons[1]}</div>
          <div className="h6 electrified-nav-center-item">{buttons[2]}</div>
        </div>
      )}
      {buttons.length <= 0 && (
        <div className="electrified-nav-right">
          <img src={CalcIcon} alt="" />
        </div>
      )}
    </nav>
  );
}

ElectrifiedNav.defaultProps = {
  pageTitle: "",
  buttons: "",
};

ElectrifiedNav.propTypes = {
  pageTitle: PropTypes.string,
  buttons: PropTypes.string,
};

export default ElectrifiedNav;
