import PropTypes from "prop-types";
import NewWindow from "react-new-window";
import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateT1Overlay({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  desc,
  url,
  setFlag,
  electrified,
  bluelink,
  charge,
  countryCode,
}) {
  return (
    <NewWindow
      title="t1_overlay_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-overlay-contents">
        <img
          src={`${GET_IMAGE_URL}${image}${countryCode ? "_DE" : ""}.png`}
          alt=""
        />
        <div>
          <div className="template-overlay-contents-item">
            <div className="h3 template-overlay-contents-title">{title}</div>
            <div className="b2 template-overlay-contents-comment">
              {comment}
            </div>
            <div className="b4 template-overlay-contents-desc">
              {desc}{" "}
              <span>
                {url !== undefined &&
                  url !== "(website’s link for WLTP depending on market)" &&
                  `${url === "" ? "" : `(${url})`}`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateT1Overlay.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  desc: "",
  url: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
  countryCode: "",
};

TemplateT1Overlay.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
  countryCode: PropTypes.bool,
};

export default TemplateT1Overlay;
