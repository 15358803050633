import PropTypes from "prop-types";

import Logo from "assets/images/Logo2.png";

import "./style.css";

function BluelinkNav({ pageTitle, buttons }) {
  return (
    <nav className="connectivity-nav">
      <span className="connectivity-nav-left">
        <img className="connectivity-nav-logo-icon" src={Logo} alt="" />
        <div className="contents-nav-left-divider"> </div>
        <div className="h6">{pageTitle}</div>
      </span>
      <div className="connectivity-bluelink-nav-center">
        {buttons.map((item) => (
          <div className="h6 connectivity-nav-center-button">{item}</div>
        ))}
      </div>
    </nav>
  );
}

BluelinkNav.defaultProps = {
  pageTitle: "",
  buttons: [],
};

BluelinkNav.propTypes = {
  pageTitle: PropTypes.string,
  buttons: PropTypes.string,
};

export default BluelinkNav;
