import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateT0Cover({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  listComment,
  countryCode,
  setFlag,
}) {
  return (
    <NewWindow
      title="t0_cover_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-cover-contents" style={{ overflow: "hidden" }}>
        <div className="template-cover-contents-list">
          {listComment
            .map((item, index) => (
              <div className="template-cover-contents-list-item">
                <img
                  className="template-cover-contents-list-item-image"
                  src={`${GET_IMAGE_URL}${image[index + 1]}.png`}
                  alt=""
                />
                <div className="template-cover-contents-list-item-title">
                  {item}
                </div>
              </div>
            ))
            .filter((_, index) => {
              if (countryCode && (index === 0 || index === 2 || index === 4)) {
                return false;
              }
              return true;
            })}
        </div>
        <div className="template-cover-contents-left">
          <div className="h2 template-cover-contents-left-title">{title}</div>
          <div className="template-cover-contents-left-comment">{comment}</div>
        </div>
        <div className="template-cover-contents-right">
          <img src={`${GET_IMAGE_URL}${image[0]}.png`} alt="" />
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={[]}
        bluelink={[]}
        charge={[]}
      />
    </NewWindow>
  );
}

TemplateT0Cover.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: [],
  title: "",
  comment: "",
  listComment: [],
  countryCode: "",
  setFlag: () => {},
};

TemplateT0Cover.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  listComment: PropTypes.string,
  countryCode: PropTypes.bool,
  setFlag: PropTypes.string,
};

export default TemplateT0Cover;
