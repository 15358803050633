const ELECTRIFIED_LIST = [
  "All About EV",
  "New KONA Electric",
  "KONA Electric",
  "IONIQ 5",
  "IONIQ 5 N",
  "IONIQ 6",
];
const POWERTRAIN_LIST = [
  "Full Electric",
  "Mild Hybrid",
  "Hydrogen",
  "Plug-in Hybrid",
  "Hybrid",
];
const CONNECTIVTY_LIST = ["Bluelink", "Charge myHyundai"];

export const getPageTitle = (item) => {
  if (ELECTRIFIED_LIST.includes(item)) return "Electrified";
  if (POWERTRAIN_LIST.includes(item)) return "Powertrain";
  if (CONNECTIVTY_LIST.includes(item)) return "Connectivity";
  return "none";
};

export const getPageIndex = (item) => {
  if (ELECTRIFIED_LIST.includes(item)) return ELECTRIFIED_LIST.indexOf(item);
  if (POWERTRAIN_LIST.includes(item)) return POWERTRAIN_LIST.indexOf(item);
  if (CONNECTIVTY_LIST.includes(item)) return CONNECTIVTY_LIST.indexOf(item);
  return -1;
};
