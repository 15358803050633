import { useEffect, useState } from "react";
// import { Cookies } from "react-cookie";

import PlaceholderImage from "assets/images/placeholder.png";

import MDAlert from "components/MDAlert";
// import MDSnackbar from "components/MDSnackbar";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Card } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import FormField from "layouts/pages/account/components/FormField";

import PreElectrifiedPage from "layouts/preview/ElectrifiedPage";
import PrePowertrainPage from "layouts/preview/PowertrainPage";
import PreConnectivityPage from "layouts/preview/ConnectivityPage";
import PreCalculatorPage from "layouts/preview/CalculatorPage";
import PreGoHomePage from "layouts/preview/GoToHomePage";
import PreBluelinkNavPage from "layouts/preview/BluelinkNavPage";
import PreChargeNavPage from "layouts/preview/ChargeNavPage";

import PreTemplateT0Cover from "layouts/preview/T0_Cover";
import PreTemplateT1Full from "layouts/preview/T1_Full";
import PreTemplateT1Half from "layouts/preview/T1_Half";
import PreTemplateT1Horizontal from "layouts/preview/T1_Horizontal";
import PreTemplateT1Overlay from "layouts/preview/T1_Overlay";
import PreTemplateT1Vertical from "layouts/preview/T1_Vertical";
import PreTemplateT2Aspect11 from "layouts/preview/T2_1-1";
import PreTemplateT2Aspect43 from "layouts/preview/T2_4-3";
import PreTemplateT2Aspect169 from "layouts/preview/T2_16-9";
import PreTemplateT2Aspect169Accordion from "layouts/preview/T2_16-9_Accordion";
import PreTemplateT2Aspect169Horizontal from "layouts/preview/T2_16-9_Horizontal";
import PreTemplateT2NumberAspect43 from "layouts/preview/T2_Number_4-3";
import PreTemplateT2NumberAspect169 from "layouts/preview/T2_Number_16-9";
import PreTemplateT2NumberAspect169Horizontal from "layouts/preview/T2_Number_16-9_Horizontal";
import PreTemplateT2NumberAspect160Vertical from "layouts/preview/T2_Number_16-9_Vertical";
import PreNotPrepraed from "layouts/preview/template_not_prepared";

import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import useLanguageStore from "utils/stores/language.store";
// import useDealerStore from "utils/stores/dealer.store";
// import useMainTemplateStore from "utils/stores/maintemplate.store";
import { getCountry } from "utils/functions/country";
import { getCookie } from "utils/functions/cookie";
import { getLanguageName } from "utils/functions/language";
import {
  SHOWROOMS,
  LANGUAGE,
  TMP_ELECTRIFIED,
  TMP_POWERTRAIN,
  TMP_CONNECTIVITY,
  ELECTRIFIED,
  POWERTRAIN,
  CONNECTIVTY,
  GET_IMAGE_URL,
} from "utils/constants";
import {
  // getTranslations,
  displayTemplate,
  editTemplate,
} from "utils/functions/axios";
import useDealerStore from "utils/stores/dealer.store";
import { getPageLength } from "utils/functions/translation";
// import itemCard from "./components/ElectifiedItemCard";
import templateItemCard from "./components/TemplateItemCard";
import CountrySelectorCard from "./components/CountrySelectCard";
import templateInputField from "./components/TemplateInputField";

function Translation() {
  // variable: //
  const { language, change, setChange } = useLanguageStore();
  const { dealer } = useDealerStore();
  // const { setMainTemplate } = useMainTemplateStore();

  const [init, setInit] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [translationLanguage, setTranslationLanguage] = useState("");
  const [countryCode, setCountryCode] = useState("");
  // const [itemGroup, setItemGroup] = useState("");
  const [category, setCategory] = useState("");
  const [vehicle, setVehicle] = useState("");

  const [translationVersion, setTranslationVersion] = useState(null);
  // const [translationItem, setTranslationItem] = useState(null);
  // const [originalItem, setOriginalItem] = useState(null);
  // const [translations, setTranslations] = useState(null);

  // description:
  const [countryInfo, setCountryInfo] = useState({});

  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [desc, setDesc] = useState("");
  const [url, setUrl] = useState("");
  const [header, setHeader] = useState("");
  const [accordionHeader, setAccordionHeader] = useState([]);
  const [accordionBody, setAccordionBody] = useState([]);
  const [imgDesc, setImgDesc] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [templateData, setTemplateData] = useState(null);
  const [selectedTemplateData, setSelectedTemplateData] = useState(null);
  const [originalTemplateData, setOriginalTemplateData] = useState(null);
  const [selectedUnchangedTemplateData, setSelectedUnchangedTemplateData] =
    useState(null);
  const [itemTitle, setItemTitle] = useState(null);
  const [templateTitle, setTemplateTitle] = useState(null);

  const [templatePopup, setTemplatePopup] = useState("none");
  const [templatePopupItem, setTemplatePopupItem] = useState(null);
  const [temporarySave, setTemporarySave] = useState(false);
  // description: template2 preview 관련 (popup flag, popup content) //
  // const [template2Pop, setTemplate2Pop] = useState(false);
  // const [template2PopItem, setTemplate2PopItem] = useState(null);
  // description: template3 preview 관련 (popup flag, popup content) //
  // const [template3Pop, setTemplate3Pop] = useState(false);
  // const [template3PopItem, setTemplate3PopItem] = useState(null);

  const [pageMainData, setPageMainData] = useState({});
  const [originalMainData, setOriginalMainData] = useState({});
  const [pageMainName, setPageMainName] = useState("");
  const [selectedPageMainData, setSelectedPageMainData] = useState(null);
  const [selectedOriginalData, setSelectedOriginalData] = useState(null);

  const [pageTitle, setPageTitle] = useState([]);
  const [pageDesc, setPageDesc] = useState([]);
  const [pageItem, setPageItem] = useState([]);
  const [navBluelink, setNavBluelink] = useState([]);
  const [navCharge, setNavCharge] = useState([]);
  const [calcDetail, setCalcDetail] = useState([]);
  const [calcComment, setCalcComment] = useState("");
  const [calcIndex, setCalcIndex] = useState(0);
  const [goHomeText, setGoHomeText] = useState("");
  const [learnText, setLearnText] = useState("");

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScroll, setIsScroll] = useState(false);

  const [saveStatus, setSaveStatus] = useState("SAVED");

  const [validateError, setValidateError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [urlValidateError, setUrlValidateError] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const [switching, setSwitching] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  // function: //
  const listReset = (templateItems, enTemplateItems) => {
    const t = getPageLength(templateItems.translations);
    setTotalPages(t);
    setOriginalTemplateData(enTemplateItems.translations);
    setTemplateData(templateItems.translations);
    setTranslationVersion(templateItems.translations.translation_version);

    setPageMainData(templateItems.pageMainTemplate);
    setOriginalMainData(enTemplateItems.pageMainTemplate);

    setSelectedTemplateData(null);
    setSelectedUnchangedTemplateData(null);

    setGoHomeText("");
    setLearnText("");
  };

  const clearTemplateData = () => {
    setOriginalTemplateData(null);
    setSelectedPageMainData(null);
    setSelectedTemplateData(null);
    setTemplateData(null);
  };

  const onCategoryHandler = (e, v) => {
    clearTemplateData();

    setVehicle("");

    if (v === null) {
      setCategory("");
    } else {
      setCategory(v.name);
    }
  };
  const onTranslationLanguageHandler = (e, v) => {
    clearTemplateData();

    setCategory("");
    setVehicle("");

    if (v === null) {
      setTranslationLanguage("");
    } else {
      setTranslationLanguage(v.name);
    }
  };
  const onVehicleHandler = async (e, v) => {
    clearTemplateData();

    if (language === "HME") return;

    setSwitching(true);

    if (v === null) return;

    setVehicle(v.name);

    const enTemplateItems = await displayTemplate(
      "default",
      "default",
      category,
      v.name
    );

    const templateItems = await displayTemplate(
      countryInfo.code,
      translationLanguage,
      category,
      v.name
    );

    if (enTemplateItems.result && templateItems.result) {
      setTimeout(() => listReset(templateItems, enTemplateItems), 100);
    }

    setSwitching(false);
  };

  const handlePopupTemplate = (status, template = null) => {
    if (inputChange) {
      setAlertStatus(true);
    } else {
      let item;
      let type = "";
      if (template !== null) {
        type = template.template_name;
        item = template.template_data;
      } else {
        item =
          status === "original"
            ? selectedUnchangedTemplateData
            : selectedTemplateData;
      }

      if (type !== "") setTemplateTitle(type);

      setTemplatePopup(status);
      setTemplatePopupItem(item);
    }
    // setMainTemplate(item);
  };

  // todo: 여기 작업
  const handleMainPagePopupTemplate = (status, cardTitle) => {
    if (inputChange) {
      setAlertStatus(true);
    } else {
      const item = status === "translated" ? pageMainData : originalMainData;

      if (cardTitle !== undefined) setTemplateTitle(cardTitle);
      else setTemplateTitle(pageMainName);
      setTemplatePopup(status);
      setTemplatePopupItem(item);
    }
  };

  // const getMainTemplateData = () => {
  //   let item;

  //   if (templatePopup === "original") item = originalMainData;
  //   else item = pageMainData;

  //   setMainTemplate(item);
  // };

  const popupTemplate = () => {
    const item = templatePopupItem;
    const mainData =
      templatePopup === "original" ? originalMainData : pageMainData;
    // getMainTemplateData();

    let index = 0;

    if (vehicle === "New KONA Electric") index = 1;
    else if (vehicle === "KONA Electric") index = 2;
    else if (vehicle === "IONIQ 5") index = 3;
    else if (vehicle === "IONIQ 5 N") index = 4;
    else if (vehicle === "IONIQ 6") index = 5;

    const navSubtitle = [vehicle];

    switch (vehicle) {
      case "All About EV":
      case "New KONA Electric":
      case "KONA Electric":
      case "IONIQ 5":
      case "IONIQ 5 N":
      case "IONIQ 6":
        for (let i = 0; i < 6; i += 1) {
          navSubtitle.push(mainData.electrified.pageTitle[i]);
        }
        break;
      case "Full Electric":
      case "Mild Hybrid":
      case "Hydrogen":
      case "Plug-in Hybrid":
      case "Hybrid":
        for (let i = 0; i < 5; i += 1) {
          navSubtitle.push(mainData.powertrain.pageTitle[i]);
        }
        break;
      case "Bluelink":
      case "Charge myHyundai":
        for (let i = 0; i < 2; i += 1) {
          navSubtitle.push(mainData.connectivity.pageTitle[i]);
        }
        break;

      default:
        break;
    }

    switch (templateTitle) {
      case "Main":
        return (
          <>
            {category === "Electrified" && vehicle === "All About EV" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={0}
                image=""
                imageList=""
                countryCode={countryCode === "PT" && vehicle === "All About EV"}
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Electrified" && vehicle === "New KONA Electric" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={1}
                image=""
                imageList=""
                countryCode={
                  countryCode === "PT" && vehicle === "New KONA Electric"
                }
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Electrified" && vehicle === "KONA Electric" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={2}
                image=""
                imageList=""
                countryCode={
                  countryCode === "PT" && vehicle === "KONA Electric"
                }
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Electrified" && vehicle === "IONIQ 5" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={3}
                image=""
                imageList=""
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Electrified" && vehicle === "IONIQ 5 N" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={4}
                image=""
                imageList=""
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Electrified" && vehicle === "IONIQ 6" && (
              <PreElectrifiedPage
                subtitle={item.electrified.pageTitle}
                itemTitle={item.electrified.pageItem}
                index={5}
                image=""
                imageList=""
                setFlag={setTemplatePopup}
                learn={item.learn}
              />
            )}
            {category === "Powertrain" && (
              <PrePowertrainPage
                subtitle={item.powertrain.pageTitle}
                itemTitle={item.powertrain.pageDesc}
                learn={item.learn}
                setFlag={setTemplatePopup}
              />
            )}
            {category === "Connectivity" && (
              <PreConnectivityPage
                subtitle={item.connectivity.pageTitle}
                itemTitle={item.connectivity.pageDesc}
                learn={item.learn}
                setFlag={setTemplatePopup}
              />
            )}
          </>
        );

      case "Calculator":
        return (
          <PreCalculatorPage
            calculator={item.electrified.calculator[index - 1]}
            calcDetail={item.electrified.calcDetail}
            index={index - 1}
            country={language}
            setFlag={setTemplatePopup}
          />
        );

      case "Go to Home":
        return (
          <PreGoHomePage goHome={item.goHome} setFlag={setTemplatePopup} />
        );

      case "Navigation":
        return (
          <>
            {vehicle === "Bluelink" && (
              <PreBluelinkNavPage
                pageItem={item.connectivity.bluelinks}
                pageTitle={item.connectivity.pageTitle[0]}
                setFlag={setTemplatePopup}
              />
            )}
            {vehicle === "Charge myHyundai" && (
              <PreChargeNavPage
                pageItem={item.connectivity.charges}
                pageTitle={item.connectivity.pageTitle[1]}
                setFlag={setTemplatePopup}
              />
            )}
          </>
        );

      case "T0_Cover":
        return (
          <PreTemplateT0Cover
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            title={item.title}
            comment={item.comment}
            listComment={item.list}
            countryCode={countryCode === "DE" && vehicle === "Mild Hybrid"}
            setFlag={setTemplatePopup}
          />
        );

      case "T1_Full":
        return (
          <PreTemplateT1Full
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
            countryCode={countryCode === "DE" && vehicle === "Mild Hybrid"}
          />
        );

      case "T1_Half":
        return (
          <PreTemplateT1Half
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T1_Horizontal":
        return (
          <PreTemplateT1Horizontal
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            title={item.title}
            comment={item.comment}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T1_Overlay":
        return (
          <PreTemplateT1Overlay
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            url={item.url}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
            countryCode={countryCode === "DE" && vehicle === "Mild Hybrid"}
          />
        );

      case "T1_Vertical":
        return (
          <PreTemplateT1Vertical
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            header={item.header}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_1-1":
        return (
          <PreTemplateT2Aspect11
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            comment={item.img_desc}
            desc={item.desc}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_4-3":
        return (
          <PreTemplateT2Aspect43
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image.length > 0 ? item.image[0] : item.video[0]}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            header={item.header}
            url={item.url}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_16-9":
        return (
          <PreTemplateT2Aspect169
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image.length > 0 ? item.image : item.video}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            header={item.header}
            url={item.url}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_16-9_Accordion":
        return (
          <PreTemplateT2Aspect169Accordion
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            header={item.header}
            accordionHeader={item.accordion_header}
            accordionBody={item.accordion_body}
            desc={item.desc}
            url={item.url}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_16-9_Horizontal":
        return (
          <PreTemplateT2Aspect169Horizontal
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            title={item.title}
            comment={item.comment}
            header={item.header}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_Number_4-3":
        return (
          <PreTemplateT2NumberAspect43
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            url={item.url}
            currentSequence={item.content_sequence}
            maxSequence={4}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      case "T2_Number_16-9":
        return (
          <PreTemplateT2NumberAspect169
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image[0]}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            url={item.url}
            currentSequence={item.content_sequence}
            maxSequence={4}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
            countryCode={countryCode === "PT" && vehicle === "KONA Electric"}
          />
        );

      case "T2_Number_16-9_Horizontal":
        return (
          <PreTemplateT2NumberAspect169Horizontal
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            url={item.url}
            currentSequence={item.content_sequence}
            maxSequence={3}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
            countryCode={countryCode === "PT" && vehicle === "KONA Electric"}
          />
        );

      case "T2_Number_16-9_Vertical":
        return (
          <PreTemplateT2NumberAspect160Vertical
            subtitle={navSubtitle}
            itemTitle={itemTitle}
            image={item.image}
            title={item.title}
            comment={item.comment}
            desc={item.desc}
            currentSequence={item.content_sequence}
            maxSequence={4}
            setFlag={setTemplatePopup}
            electrified={mainData.electrified.pageItem}
            bluelink={mainData.connectivity.bluelinks}
            charge={mainData.connectivity.charges}
          />
        );

      default:
        return <PreNotPrepraed setFlag={setTemplatePopup} />;
    }
  };

  useEffect(() => {
    if (!change && init) return;

    let country = language;

    if (!country) {
      const countryCookie = getCookie("country");
      const { country: dealerCountry } = dealer;
      country = countryCookie !== undefined ? countryCookie : dealerCountry;
    }

    // const country = language;
    const countryData = getCountry(country);
    setCountryInfo(countryData);
    setCountryCode(country);
    setChange(false);
    setInit(true);
  }, [change, language]);

  // component:  //
  function categorySelectorCard() {
    return (
      <Grid item sm={12} lg={3}>
        <Card>
          <MDBox p="16px">
            <MDTypography variant="b7" color="deepGray">
              App Type
            </MDTypography>
            <Autocomplete
              id="categoryAutoComplete"
              color="darkGray"
              onChange={(event, value) => onCategoryHandler(event, value)}
              options={SHOWROOMS}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="SHOWROOMS"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
        </Card>
      </Grid>
    );
  }

  // component:  //
  function vehicleSelectorCard() {
    let option;

    if (category === ELECTRIFIED) option = TMP_ELECTRIFIED;
    else if (category === POWERTRAIN) option = TMP_POWERTRAIN;
    else if (category === CONNECTIVTY) option = TMP_CONNECTIVITY;

    return (
      <Grid item sm={12} lg={3}>
        <Card>
          <MDBox p="16px">
            <MDTypography variant="b7" color="deepGray">
              Category
            </MDTypography>
            <Autocomplete
              id="vehicleAutoComplete"
              onChange={(event, value) => onVehicleHandler(event, value)}
              options={option}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="VEHICLE"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
        </Card>
      </Grid>
    );
  }

  // component:  //
  function languageSelectorCard() {
    return (
      <Grid item sm={12} lg={3}>
        <Card style={{ height: "100%" }}>
          <MDBox p="16px">
            <MDTypography color="deepGray" variant="b7">
              Language
            </MDTypography>
            <Autocomplete
              id="languageAutoComplete"
              onChange={(event, value) =>
                onTranslationLanguageHandler(event, value)
              }
              options={LANGUAGE}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="LANGUAGE"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
        </Card>
      </Grid>
    );
  }

  const validateFields = (changedData) => {
    let empty = false;
    let validate = false;

    if (changedData.header !== undefined) {
      if (header === "") empty = true;
    }
    if (changedData.title !== undefined) {
      if (title === "") empty = true;
    }
    if (changedData.comment !== undefined) {
      if (comment === "") empty = true;
    }
    if (changedData.desc !== undefined) {
      if (desc === "") empty = true;
    }
    if (changedData.url !== undefined) {
      if (
        url !== "(website’s link for WLTP depending on market)" &&
        url !== ""
      ) {
        // const expUrl = /^http[s]?:\/\/([\S]{3,})/i;
        const expUrl =
          /(http|https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
        const test = expUrl.test(url);

        if (!test) validate = true;
      }
    }
    if (changedData.accordion_header !== undefined) {
      for (let i = 0; i < changedData.accordion_header.length; i += 1) {
        if (accordionHeader[i] === "") empty = true;
      }
    }
    if (changedData.accordion_body !== undefined) {
      for (let i = 0; i < changedData.accordion_body.length; i += 1) {
        if (selectedUnchangedTemplateData.accordion_body[i] !== "") {
          if (accordionBody[i] === "") empty = true;
        }
      }
    }
    if (changedData.img_desc !== undefined) {
      for (let i = 0; i < changedData.img_desc.length; i += 1) {
        if (changedData.img_desc[i] !== "") {
          if (imgDesc[i] === "") empty = true;
        }
      }
    }
    if (changedData.list !== undefined) {
      for (let i = 0; i < changedData.list.length; i += 1) {
        if (changedData.list[i] !== "") {
          if (vehicleList[i] === "") empty = true;
        }
      }
    }

    setEmptyError(empty);
    setUrlValidateError(validate);

    return !(empty || validate);
  };

  const saveTemporary = () => {
    const filter = templateData;

    const itemIndex = selectedTemplateData.content_sequence;
    let pageIndex = 0;

    for (let i = 0; i < filter.pages.length; i += 1) {
      if (filter.pages[i].item_title === itemTitle) {
        pageIndex = i;
        break;
      }
    }

    const changedData = selectedTemplateData;

    if (!validateFields(changedData)) {
      setValidateError(true);
    } else {
      if (changedData.header !== undefined) {
        changedData.header = header;
      }
      if (changedData.title !== undefined) {
        changedData.title = title;
      }
      if (changedData.comment !== undefined) {
        changedData.comment = comment;
      }
      if (changedData.desc !== undefined) {
        changedData.desc = desc;
      }
      if (changedData.url !== undefined) {
        let urlData = url;
        if (urlData === "")
          urlData = "(website’s link for WLTP depending on market)";
        changedData.url = urlData;
      }
      if (changedData.accordion_header !== undefined) {
        for (let i = 0; i < changedData.accordion_header.length; i += 1) {
          if (changedData.accordion_header[i] !== "") {
            changedData.accordion_header[i] = accordionHeader[i];
          }
        }
      }
      if (changedData.accordion_body !== undefined) {
        for (let i = 0; i < changedData.accordion_body.length; i += 1) {
          if (changedData.accordion_body[i] !== "") {
            changedData.accordion_body[i] = accordionBody[i];
          }
        }
      }
      if (changedData.img_desc !== undefined) {
        for (let i = 0; i < changedData.img_desc.length; i += 1) {
          if (changedData.img_desc[i] !== "") {
            changedData.img_desc[i] = imgDesc[i];
          }
        }
      }
      if (changedData.list !== undefined) {
        for (let i = 0; i < changedData.list.length; i += 1) {
          if (changedData.list[i] !== "") {
            changedData.list[i] = vehicleList[i];
          }
        }
      }

      filter.pages[pageIndex].items[itemIndex].template_data = changedData;

      setTemplateData(filter);
      setTemporarySave(true);
      setValidateError(false);
      setEmptyError(false);
      setUrlValidateError(false);
      setInputChange(false);

      setSelectedTemplateData(null);
      setSelectedUnchangedTemplateData(null);
      setTemplatePopup("none");
      setSaveStatus("SAVE");
    }
  };

  const validateFields2 = () => {
    if (pageMainName === "Main") {
      if (category === "Electrified") {
        for (let i = 0; i < pageTitle.length; i += 1) {
          if (pageTitle[i] === "") return false;
        }
        for (let i = 0; i < pageItem.length; i += 1) {
          if (pageItem[i] === "") return false;
        }
      } else if (category === "Powertrain") {
        for (let i = 0; i < pageTitle.length; i += 1) {
          if (pageTitle[i] === "") return false;
        }
        for (let i = 0; i < pageDesc.length; i += 1) {
          if (pageDesc[i] === "") return false;
        }
        if (learnText === "") return false;
      } else if (category === "Connectivity") {
        for (let i = 0; i < pageTitle.length; i += 1) {
          if (pageTitle[i] === "") return false;
        }
        for (let i = 0; i < pageDesc.length; i += 1) {
          if (pageDesc[i] === "") return false;
        }
        if (learnText === "") return false;
      }
    } else if (pageMainName === "Calculator") {
      if (calcComment === "") return false;
      for (let i = 0; i < calcDetail.length; i += 1) {
        if (calcDetail[i] === "") return false;
      }
    } else if (pageMainName === "Navigation") {
      if (navBluelink.length > 0) {
        for (let i = 0; i < navBluelink.length; i += 1) {
          if (navBluelink[i] === "") return false;
        }
      }
      if (navCharge.length > 0) {
        for (let i = 0; i < navCharge.length; i += 1) {
          if (navCharge[i] === "") return false;
        }
      }
    } else if (pageMainName === "Go to Home") {
      if (goHomeText === "") return false;
    }

    return true;
  };

  const saveTemporary2 = () => {
    const tmp = pageMainData;

    if (!validateFields2()) {
      setEmptyError(true);
      setValidateError(true);
    } else {
      if (goHomeText !== "") tmp.goHome = goHomeText;
      if (learnText !== "") tmp.learn = learnText;

      if (navBluelink.length > 0) tmp.connectivity.bluelinks = navBluelink;
      if (navCharge.length > 0) tmp.connectivity.charges = navCharge;

      if (category === "Electrified") {
        const tmpCalc = [...tmp.electrified.calculator];
        let index = 0;
        if (vehicle === "KONA Electric") index = 1;
        else if (vehicle === "IONIQ 5") index = 2;
        else if (vehicle === "IONIQ 5 N") index = 3;
        else if (vehicle === "IONIQ 6") index = 4;
        tmpCalc[index] = calcComment;

        if (pageTitle.length > 0) tmp.electrified.pageTitle = pageTitle;
        if (pageItem.length > 0) tmp.electrified.pageItem = pageItem;
        if (calcDetail.length > 0) tmp.electrified.calcDetail = calcDetail;
        if (tmpCalc.length > 0) tmp.electrified.calculator = tmpCalc;
      } else if (category === "Powertrain") {
        if (pageTitle.length > 0) tmp.powertrain.pageTitle = pageTitle;
        if (pageDesc.length > 0) tmp.powertrain.pageDesc = pageDesc;
        // tmp.learn = learnText;
      } else if (category === "Connectivity") {
        if (pageTitle.length > 0) tmp.connectivity.pageTitle = pageTitle;
        if (pageDesc.length > 0) tmp.connectivity.pageDesc = pageDesc;
        // tmp.learn = learnText;
      }

      setInputChange(false);
      setTemporarySave(true);
      setValidateError(false);
      setEmptyError(false);
      setPageMainData(tmp);
      setSelectedPageMainData(null);
      setPageMainName("");
      setSaveStatus("SAVE");
    }
  };

  const saveChangedData = async () => {
    if (!temporarySave) return;

    setSwitching(true);

    let newVersion = parseFloat(translationVersion);
    newVersion += 0.1;
    newVersion = newVersion.toFixed(1);

    templateData.translation_version = newVersion;

    await editTemplate(
      countryInfo.code,
      translationLanguage,
      templateData.page_title,
      templateData.page_subtitle,
      pageMainData,
      templateData
    ).then(() => {
      setSaveStatus("SAVED");
      setSwitching(false);
    });

    setTranslationVersion(newVersion);
    setTemporarySave(false);
  };

  function handleTitle(e) {
    setTitle(e.target.value);
    setInputChange(true);
  }
  function handleComment(e) {
    setComment(e.target.value);
    setInputChange(true);
  }
  function handleDesc(e) {
    setDesc(e.target.value);
    setInputChange(true);
  }
  function handleUrl(e) {
    setUrl(e.target.value);
    setInputChange(true);
  }
  function handleHeader(e) {
    setHeader(e.target.value);
    setInputChange(true);
  }
  function handleAccordionHeader(e, index) {
    const temp = [...accordionHeader];
    temp[index] = e.target.value;
    setAccordionHeader(temp);
    setInputChange(true);
  }
  function handleAccordionBody(e, index) {
    const temp = [...accordionBody];
    temp[index] = e.target.value;
    setAccordionBody(temp);
    setInputChange(true);
  }
  function handleImgDesc(e, index) {
    const temp = [...imgDesc];
    temp[index] = e.target.value;
    setImgDesc(temp);
    setInputChange(true);
  }
  function handleVehicleList(e, index) {
    const temp = [...vehicleList];
    temp[index] = e.target.value;
    setVehicleList(temp);
    setInputChange(true);
  }
  function handlePageTitle(e, index) {
    const temp = [...pageTitle];
    temp[index] = e.target.value;
    setPageTitle(temp);
    setInputChange(true);
  }
  function handlePageDesc(e, index) {
    const temp = [...pageDesc];
    temp[index] = e.target.value;
    setPageDesc(temp);
    setInputChange(true);
  }
  function handlePageItem(e, index) {
    const temp = [...pageItem];
    temp[index] = e.target.value;
    setPageItem(temp);
    setInputChange(true);
  }
  function handleBluelink(e, index) {
    const temp = [...navBluelink];
    temp[index] = e.target.value;
    setNavBluelink(temp);
    setInputChange(true);
  }
  function handleChargeMyHyundai(e, index) {
    const temp = [...navCharge];
    temp[index] = e.target.value;
    setNavCharge(temp);
    setInputChange(true);
  }
  function handleGoHome(e) {
    setGoHomeText(e.target.value);
    setInputChange(true);
  }
  function handleLearn(e) {
    setLearnText(e.target.value);
    setInputChange(true);
  }
  function handleCalcDetail(e, index) {
    const temp = [...calcDetail];
    temp[index] = e.target.value;
    setCalcDetail(temp);
    setInputChange(true);
  }
  function handleCalcComment(e) {
    setCalcComment(e.target.value);
    setInputChange(true);
  }
  function handleSelect(item) {
    const box = document.getElementById("testBox");
    setIsScroll(true);
    setScrollPosition(box.scrollLeft);

    if (item.title !== undefined) setTitle(item.title);
    if (item.comment !== undefined) setComment(item.comment);
    if (item.desc !== undefined) setDesc(item.desc);
    if (item.url !== undefined) setUrl(item.url);
    if (item.header !== undefined) setHeader(item.header);
    if (item.accordion_header !== undefined) {
      setAccordionHeader(item.accordion_header);
    }
    if (item.accordion_body !== undefined) {
      setAccordionBody(item.accordion_body);
    }
    if (item.img_desc !== undefined) {
      setImgDesc(item.img_desc);
    }
    if (item.list !== undefined) {
      setVehicleList(item.list);
    }
  }

  function mainCardViewer() {
    return (
      <MDBox id="main-card-viewer">
        <Card>
          <MDBox p="24px">
            <MDBox mb="24px">
              <MDTypography variant="h6">
                {/* {`${category} > ${vehicle} > ${pageMainName}`} */}
                {pageMainName === "Main" && `${category} > ${pageMainName}`}
                {pageMainName === "Navigation" &&
                  `${category} > ${vehicle} > ${pageMainName}`}
                {pageMainName === "Go to Home" && `${pageMainName}`}
                {pageMainName === "Calculator" &&
                  `${category} > ${pageMainName}`}
              </MDTypography>
            </MDBox>
            {validateError && (
              <MDAlert
                style={{ whiteSpace: "pre-wrap", backgroundColor: "blue" }}
                color="error"
              >
                {`${emptyError ? "Please fill in every fields.\n" : ""}${
                  urlValidateError ? "Please enter a valid url." : ""
                }`}
              </MDAlert>
            )}
            {pageMainName === "Main" &&
              selectedPageMainData.pageTitle !== undefined &&
              selectedPageMainData.pageTitle.map((item, i) =>
                templateInputField(
                  selectedOriginalData.pageTitle[i],
                  item,
                  handlePageTitle,
                  false,
                  i
                )
              )}
            {pageMainName === "Main" &&
              selectedPageMainData.pageItem !== undefined &&
              selectedPageMainData.pageItem.map((item, i) =>
                templateInputField(
                  selectedOriginalData.pageItem[i],
                  item,
                  handlePageItem,
                  false,
                  i
                )
              )}
            {pageMainName === "Main" &&
              selectedPageMainData.pageDesc !== undefined &&
              selectedPageMainData.pageDesc.map((item, i) =>
                templateInputField(
                  selectedOriginalData.pageDesc[i],
                  item,
                  handlePageDesc,
                  false,
                  i
                )
              )}
            {pageMainName === "Main" &&
              templateInputField(
                selectedOriginalData.learn,
                selectedPageMainData.learn,
                handleLearn,
                false
              )}
            {pageMainName === "Navigation" &&
              vehicle === "Bluelink" &&
              selectedPageMainData.bluelinks.map((item, i) =>
                templateInputField(
                  selectedOriginalData.bluelinks[i],
                  item,
                  handleBluelink,
                  false,
                  i
                )
              )}
            {pageMainName === "Navigation" &&
              vehicle === "Charge myHyundai" &&
              selectedPageMainData.charges.map((item, i) =>
                templateInputField(
                  selectedOriginalData.charges[i],
                  item,
                  handleChargeMyHyundai,
                  false,
                  i
                )
              )}
            {pageMainName === "Go to Home" &&
              templateInputField(
                selectedOriginalData.goHome,
                selectedPageMainData.goHome,
                handleGoHome,
                false
              )}
            {pageMainName === "Calculator" &&
              vehicle !== "All About EV" &&
              selectedPageMainData.calcDetail.map((item, i) =>
                templateInputField(
                  selectedOriginalData.calcDetail[i],
                  item,
                  handleCalcDetail,
                  false,
                  i
                )
              )}
            {pageMainName === "Calculator" &&
              templateInputField(
                selectedOriginalData.calculator[calcIndex],
                selectedPageMainData.calculator[calcIndex],
                handleCalcComment,
                false
              )}
            <MDBox mt="24px">
              <Grid container>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="sand"
                    fullWidth
                    onClick={() => {
                      handleMainPagePopupTemplate("original");
                    }}
                  >
                    <MDTypography variant="b7" color="brown">
                      PREVIEW ORIGINAL PAGE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="sand"
                    fullWidth
                    onClick={() => {
                      handleMainPagePopupTemplate("translated");
                    }}
                  >
                    <MDTypography variant="b7" color="brown">
                      PREVIEW TRANSLATED PAGE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="blue"
                    fullWidth
                    onClick={() => {
                      saveTemporary2();
                    }}
                  >
                    <MDTypography variant="b7" color="white">
                      SAVE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="blue"
                    fullWidth
                    onClick={() => {
                      // setSelectedTemplateData(null);
                      // setSelectedUnchangedTemplateData(null);
                      // setTemplatePopup("none");
                      setValidateError(false);
                      setEmptyError(false);
                      setUrlValidateError(false);
                      setSelectedPageMainData(null);
                      setInputChange(false);
                    }}
                  >
                    <MDTypography variant="b7" color="white">
                      BACK
                    </MDTypography>
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function mainTemplateCard(cardTitle) {
    let imgSrc = GET_IMAGE_URL;

    if (cardTitle === "Main") {
      if (vehicle === "All About EV") {
        imgSrc += "main_AllAboutEV.png";
      } else if (vehicle === "New KONA Electric") {
        imgSrc += "main_NewKONAElectric.png";
      } else if (vehicle === "KONA Electric") {
        imgSrc +=
          countryCode === "PT"
            ? "main_KONAElectric_portu.png"
            : "main_KONAElectric.png";
      } else if (vehicle === "IONIQ 5") {
        imgSrc += "main_IONIQ5.png";
      } else if (vehicle === "IONIQ 5 N") {
        imgSrc += "main_IONIQ5N.png";
      } else if (vehicle === "IONIQ 6") {
        imgSrc += "main_IONIQ6.png";
      } else if (category === "Powertrain") {
        imgSrc += "main_Powertrain.png";
      } else if (category === "Connectivity") {
        imgSrc += "main_Connectivity.png";
      }
    } else if (cardTitle === "Calculator") {
      if (vehicle === "New KONA Electric") {
        imgSrc += "calculator_NewKONAElectric.png";
      } else if (vehicle === "KONA Electric") {
        imgSrc += "calculator_KONAElectric.png";
      } else if (vehicle === "IONIQ 5") {
        imgSrc += "calculator_IONIQ5.png";
      } else if (vehicle === "IONIQ 5 N") {
        imgSrc += "calculator_IONIQ5N.png";
      } else if (vehicle === "IONIQ 6") {
        imgSrc += "calculator_IONIQ6.png";
      }
    } else if (cardTitle === "Go to Home") {
      imgSrc += "Go to home.jpg";
    } else {
      imgSrc = "null";
    }

    return (
      <MDBox
        my={1}
        mx={1.5}
        style={{ display: "inline-block", width: "240px" }}
      >
        <Card style={{ backgroundColor: "#F6F3F2", boxShadow: "none" }}>
          <MDBox p={2}>
            <MDBox textAlign="center">
              <MDBox
                component="img"
                src={imgSrc !== "null" ? imgSrc : PlaceholderImage}
                style={{ width: "200px", height: "160px", objectFit: "cover" }}
              />
            </MDBox>
            <MDTypography
              mt="16px"
              color="darkGray"
              style={{
                fontFamily: "Hyundai Sans Head Medium",
                fontSize: "16px",
              }}
            >
              {cardTitle}
            </MDTypography>
            <MDBox mt="6px">
              <MDTypography
                variant="b7"
                color="text"
                opacity={0.6}
                textTransform="uppercase"
              >
                {getLanguageName(translationLanguage)}
              </MDTypography>
            </MDBox>
            <MDBox mt="19px">
              <MDButton
                color="blue"
                fullWidth
                // disabled={noTextTemplate.includes(templateTitle)}
                // onClick={() => onEditHandler()}
                onClick={() => {
                  const box = document.getElementById("testBox");
                  setIsScroll(true);
                  setScrollPosition(box.scrollLeft);

                  if (cardTitle === "Go to Home") {
                    const data = {
                      goHome: pageMainData.goHome,
                    };
                    const origin = {
                      goHome: originalMainData.goHome,
                    };
                    setGoHomeText(data.goHome);
                    setSelectedPageMainData(data);
                    setSelectedOriginalData(origin);
                  } else if (cardTitle === "Calculator") {
                    const data = pageMainData.electrified;
                    const origin = originalMainData.electrified;
                    let index = 0;
                    if (vehicle === "KONA Electric") index = 1;
                    else if (vehicle === "IONIQ 5") index = 2;
                    else if (vehicle === "IONIQ 5 N") index = 3;
                    else if (vehicle === "IONIQ 6") index = 4;
                    setPageTitle(data.pageTitle);
                    setPageItem(data.pageItem);
                    setCalcDetail(data.calcDetail);
                    setCalcComment(data.calculator[index]);
                    setCalcIndex(index);
                    setSelectedPageMainData(data);
                    setSelectedOriginalData(origin);
                  } else if (category === "Electrified") {
                    const data = pageMainData.electrified;
                    const origin = originalMainData.electrified;
                    data.learn = pageMainData.learn;
                    origin.learn = originalMainData.learn;
                    let index = 0;
                    if (vehicle === "New KONA Electric") index = 1;
                    else if (vehicle === "KONA Electric") index = 2;
                    else if (vehicle === "IONIQ 5") index = 3;
                    else if (vehicle === "IONIQ 5 N") index = 4;
                    else if (vehicle === "IONIQ 6") index = 5;
                    setPageTitle(data.pageTitle);
                    setPageItem(data.pageItem);
                    setCalcDetail(data.calcDetail);
                    setCalcComment(data.calculator[index]);
                    setSelectedPageMainData(data);
                    setSelectedOriginalData(origin);
                    setLearnText(pageMainData.learn);
                  } else if (category === "Powertrain") {
                    const data = pageMainData.powertrain;
                    const origin = originalMainData.powertrain;
                    data.learn = pageMainData.learn;
                    origin.learn = originalMainData.learn;
                    setPageTitle(data.pageTitle);
                    setPageDesc(data.pageDesc);
                    setSelectedPageMainData(data);
                    setSelectedOriginalData(origin);
                    setLearnText(pageMainData.learn);
                  } else if (category === "Connectivity") {
                    const data = pageMainData.connectivity;
                    const origin = originalMainData.connectivity;
                    data.learn = pageMainData.learn;
                    origin.learn = originalMainData.learn;
                    setPageTitle(data.pageTitle);
                    setPageDesc(data.pageDesc);
                    setSelectedPageMainData(data);
                    setSelectedOriginalData(origin);
                    setNavBluelink(data.bluelinks);
                    setNavCharge(data.charges);
                    setLearnText(pageMainData.learn);
                  }
                  setPageMainName(cardTitle);
                }}
              >
                <MDTypography variant="b7" color="white">
                  EDIT
                </MDTypography>
              </MDButton>
            </MDBox>
            <MDBox mt="16px">
              <MDButton
                style={{ backgroundColor: "#E4DCD3" }}
                fullWidth
                onClick={() =>
                  handleMainPagePopupTemplate("translated", cardTitle)
                }
              >
                <MDTypography variant="b7" color="brown">
                  PREVIEW
                </MDTypography>
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function templateItemCardList() {
    setTimeout(() => {
      if (isScroll) {
        setIsScroll(false);
        const box = document.getElementById("testBox");
        const scrollX = scrollPosition;
        box.scrollTo(scrollX, 0);
        setScrollPosition(0);
      }
    }, 1);

    return (
      <MDBox
        id="testBox"
        m={2}
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          scrollX: { scrollPosition },
        }}
      >
        {pageMainData && category === "Electrified" && mainTemplateCard("Main")}
        {pageMainData &&
          category === "Electrified" &&
          vehicle !== "All About EV" &&
          mainTemplateCard("Calculator")}
        {pageMainData && category === "Powertrain" && mainTemplateCard("Main")}
        {pageMainData &&
          category === "Connectivity" &&
          mainTemplateCard("Main")}
        {pageMainData &&
          vehicle === "Bluelink" &&
          mainTemplateCard("Navigation")}
        {pageMainData &&
          vehicle === "Charge myHyundai" &&
          mainTemplateCard("Navigation")}
        {templateData &&
          templateData.pages.map((page, pageIndex) =>
            page.items.map((item, itemIndex) =>
              templateItemCard(
                page.item_title,
                item.template_name,
                pageIndex,
                itemIndex,
                originalTemplateData,
                templateData,
                translationLanguage,
                countryCode,
                setItemTitle,
                setTemplateTitle,
                setSelectedTemplateData,
                setSelectedUnchangedTemplateData,
                handleSelect,
                handlePopupTemplate
              )
            )
          )}
        {mainTemplateCard("Go to Home")}
      </MDBox>
    );
  }

  function templateViewer() {
    return (
      <MDBox id="template-card-viewer">
        <Card>
          <MDBox p="24px">
            <MDBox mb="24px">
              <MDTypography variant="h6">
                {`${category} > ${vehicle} > ${itemTitle} #${
                  selectedTemplateData.content_sequence + 1
                }`}
              </MDTypography>
            </MDBox>
            {validateError && (
              <MDAlert color="error" style={{ whiteSpace: "pre-wrap" }}>
                {`${emptyError ? "Please fill in every fields.\n" : ""}${
                  urlValidateError ? "Please enter a valid url." : ""
                }`}
              </MDAlert>
            )}
            {selectedTemplateData.header !== undefined &&
              templateInputField(
                selectedUnchangedTemplateData.header,
                selectedTemplateData.header,
                handleHeader,
                false
              )}
            {selectedTemplateData.title !== undefined &&
              templateInputField(
                selectedUnchangedTemplateData.title,
                selectedTemplateData.title,
                handleTitle,
                true
              )}
            {selectedTemplateData.accordion_header !== undefined &&
              selectedTemplateData.accordion_header.map((item, index) =>
                templateInputField(
                  selectedUnchangedTemplateData.accordion_header[index],
                  selectedTemplateData.accordion_header[index],
                  handleAccordionHeader,
                  true,
                  index
                )
              )}
            {selectedTemplateData.accordion_body !== undefined &&
              selectedTemplateData.accordion_body.map(
                (item, index) =>
                  item !== "" &&
                  templateInputField(
                    selectedUnchangedTemplateData.accordion_body[index],
                    selectedTemplateData.accordion_body[index],
                    handleAccordionBody,
                    false,
                    index
                  )
              )}
            {selectedTemplateData.comment !== undefined &&
              templateInputField(
                selectedUnchangedTemplateData.comment,
                selectedTemplateData.comment,
                handleComment,
                false
              )}
            {selectedTemplateData.img_desc !== undefined &&
              selectedTemplateData.img_desc.map(
                (item, index) =>
                  item !== "" &&
                  templateInputField(
                    selectedUnchangedTemplateData.img_desc[index],
                    selectedTemplateData.img_desc[index],
                    handleImgDesc,
                    true,
                    index
                  )
              )}
            {selectedTemplateData.list !== undefined &&
              selectedTemplateData.list
                .map(
                  (item, index) =>
                    item !== "" &&
                    templateInputField(
                      selectedUnchangedTemplateData.list[index],
                      selectedTemplateData.list[index],
                      handleVehicleList,
                      false,
                      index
                    )
                )
                .filter((_, index) => {
                  if (
                    countryCode === "DE" &&
                    vehicle === "Mild Hybrid" &&
                    (index === 0 || index === 2 || index === 4)
                  ) {
                    return false;
                  }
                  return true;
                })}
            {selectedTemplateData.desc !== undefined &&
              templateInputField(
                selectedUnchangedTemplateData.desc,
                selectedTemplateData.desc,
                handleDesc,
                false
              )}
            {selectedTemplateData.url !== undefined &&
              templateInputField(
                selectedUnchangedTemplateData.url,
                selectedTemplateData.url,
                handleUrl,
                false,
                -1,
                "(website’s link for WLTP depending on market)"
              )}
            <MDBox mt="24px">
              <Grid container>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="sand"
                    fullWidth
                    onClick={() => {
                      handlePopupTemplate("original");
                    }}
                  >
                    <MDTypography variant="b7" color="brown">
                      PREVIEW ORIGINAL PAGE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="sand"
                    fullWidth
                    onClick={() => {
                      handlePopupTemplate("translated");
                    }}
                  >
                    <MDTypography variant="b7" color="brown">
                      PREVIEW TRANSLATED PAGE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="blue"
                    fullWidth
                    onClick={() => saveTemporary()}
                  >
                    <MDTypography variant="b7" color="white">
                      SAVE
                    </MDTypography>
                  </MDButton>
                </Grid>
                <Grid px="8px" item sm={12} lg={3}>
                  <MDButton
                    color="blue"
                    fullWidth
                    onClick={() => {
                      setSelectedTemplateData(null);
                      setSelectedUnchangedTemplateData(null);
                      setTemplatePopup("none");
                      setValidateError(false);
                      setEmptyError(false);
                      setUrlValidateError(false);
                      setInputChange(false);
                    }}
                  >
                    <MDTypography variant="b7" color="white">
                      BACK
                    </MDTypography>
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function toggleAlertComponent() {
    return (
      <MDBox
        style={{
          position: "absolute",
          top: "0",
          left: "-275px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "9999",
        }}
      >
        <Card
          style={{
            width: "600px",
            height: "218px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MDBox>
            <MDTypography variant="h6" px="20px" py="20px">
              Please save the changes
            </MDTypography>
            <MDTypography variant="b8" px="20px" pb="27px">
              Please save the changes to see the translated page.
            </MDTypography>
          </MDBox>
          <MDBox
            pr="10px"
            pb="10px"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <MDButton
              style={{
                float: "right",
                color: "#002C5f",
                width: "110px",
              }}
              onClick={() => setAlertStatus(false)}
            >
              Confirm
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  function handleTotalSaveClicked() {
    if (temporarySave) {
      setSaveStatus("SAVING...");
      setSaveClicked(!saveClicked);
      saveChangedData();
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={-4} py={1}>
        <MDBox mt={2} mb={3}>
          <Grid container spacing={1.5}>
            <CountrySelectorCard />
            {languageSelectorCard()}
            {translationLanguage && categorySelectorCard()}
            {category && vehicleSelectorCard()}
          </Grid>
        </MDBox>

        {!selectedTemplateData && !selectedPageMainData && templateData && (
          <MDBox>
            <Card>
              <MDBox p="24px">
                <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography
                    variant="body1"
                    lineHeight={1}
                    mb="24px"
                    verticalAlign="middle"
                    style={{
                      color: "#1C1B1B",
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    Translations -{" "}
                    <span style={{ color: "#00AAD2" }}>
                      v {translationVersion}
                    </span>
                  </MDTypography>
                  <MDButton
                    color="blue"
                    disabled={saveStatus !== "SAVE"}
                    onClick={() => handleTotalSaveClicked()}
                  >
                    <MDTypography
                      style={{
                        width: "110px",
                      }}
                      variant="b7"
                      color="white"
                    >
                      {saveStatus}
                    </MDTypography>
                  </MDButton>
                </MDBox>
                <Grid container>
                  <Grid item sm={12} lg={3} xl={3}>
                    <MDBox mt={1}>
                      <Card
                        style={{
                          backgroundColor: "#F6F3F2",
                          marginRight: "24px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={7}>
                            <MDTypography
                              p={2}
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                opacity: "0.3",
                              }}
                            >
                              TOTAL PAGES
                            </MDTypography>
                          </Grid>
                          <Grid item xs={5}>
                            <MDTypography
                              py={2}
                              color="black"
                              verticalAlign="middle"
                              textAlign="center"
                              variant="h4"
                            >
                              {totalPages}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Card>
                    </MDBox>
                  </Grid>
                  {/* <Grid item sm={12} md={3} xl={3}>
                    <MDBox mt={1}>
                      <Card
                        style={{
                          backgroundColor: "#F6F3F2",
                          marginRight: "24px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <MDTypography
                              p={2}
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                opacity: "0.3",
                              }}
                            >
                              UNDEFINED
                            </MDTypography>
                          </Grid>
                          <Grid item xs={5}>
                            <MDTypography
                              p={2}
                              color="activeRed"
                              textAlign="center"
                              variant="h4"
                            >
                              {untranslationPages}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item sm={12} md={3} xl={3}>
                    <MDBox mt={1}>
                      <Card
                        style={{
                          backgroundColor: "#F6F3F2",
                          marginRight: "24px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <MDTypography
                              p={2}
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                opacity: "0.3",
                              }}
                            >
                              {getLanguageName(translationLanguage)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={5}>
                            <MDTypography
                              p={2}
                              color="activeBlue"
                              textAlign="center"
                              variant="h4"
                            >
                              {translationPages}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Card>
                    </MDBox>
                  </Grid> */}
                </Grid>
              </MDBox>
              {/* {category === ELECTRIFIED && electrifiedItemCard()} */}
              <MDBox style={{ overflowX: "auto" }}>
                {templateData && templateItemCardList()}
              </MDBox>
            </Card>
          </MDBox>
        )}
        {selectedTemplateData && templateViewer()}
        {selectedPageMainData && mainCardViewer()}
      </MDBox>
      {alertStatus && toggleAlertComponent()}
      {templatePopup !== "none" && popupTemplate()}
      {/* <MDSnackbar
        color="error"
        title="error title"
        content="error content"
        open={validateError}
        close={() => setValidateError(false)}
      /> */}
      {switching && (
        <MDBox
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "start",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: "9999",
          }}
        >
          <MDBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              padding: "12px 10px",
              marginTop: "24px",
              marginRight: "24px",

              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <MDTypography>Loading...</MDTypography>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Translation;
