import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateT1Vertical({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  desc,
  header,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t1_vertical_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-vertical-contents">
        <div className="template-vertical-contents-left">
          <div>
            <div className="deep-gray h6 template-vertical-contents-left-header">
              {header}
            </div>
            <div className="dark-gray h3 template-vertical-contents-left-title">
              {title}
            </div>
            <div className="b2 template-vertical-contents-left-comment">
              {comment}
            </div>
          </div>
          <div className="deep-gray b6 template-vertical-contents-left-desc">
            {desc}
          </div>
        </div>
        <div className="template-vertical-contents-right">
          <img src={`${GET_IMAGE_URL}${image}.png`} alt="" />
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateT1Vertical.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  desc: "",
  header: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

TemplateT1Vertical.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  desc: PropTypes.string,
  header: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default TemplateT1Vertical;
