import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import rightvector from "assets/images/rightvector.png";

import "./style.css";
import "../common.css";

import Nav from "../nav";

const list = [
  "ALL_ABOUT_EV",
  "NEW_KONA_ELECTRIC",
  "KONA_ELECTRIC",
  "IONIQ_5",
  "IONIQ_5_N",
  "IONIQ_6",
];

function ElectrifiedPage({
  subtitle,
  itemTitle,
  index,
  setFlag,
  countryCode,
  learn,
}) {
  const getClassName = (idx) => {
    let str = "electrified-standalone-car";

    str += index !== idx ? " blur-btn" : "";

    return str;
  };

  let portu = "";
  if (countryCode) {
    portu = "_portu";
  }

  return (
    <NewWindow
      title="electrified_main_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="electrified-standalone-content">
        <div className="electrified-standalone-background">
          <img
            src={`${GET_IMAGE_URL}${
              index === 0 ? "backtest2.png" : "backtest.png"
            }`}
            alt=""
          />
        </div>
        <div className="electrified-standalone-content-wrapper">
          <div className="electrified-standalone-top">
            <img
              className="electrified-standalone-top-rot"
              src={`${GET_IMAGE_URL}${list[index]}_01${portu}.png`}
              alt=""
            />
            {index === 0 ? (
              <div className="electrified-standalone-menu-allaboutev">
                <div className="deep-gray b2 electrified-standalone-menu-year standalone-not-display">
                  2023
                </div>
                <h2 className="h2 electrified-standalone-menu-title">
                  {subtitle[index]}
                </h2>

                <div className="electrified-standalone-menu-wrapper">
                  <div className="electrified-standalone-menu-wrapper-left">
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-title">
                        {itemTitle[0]}
                      </div>
                    </div>
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-title">
                        {itemTitle[1]}
                      </div>
                    </div>
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-title">
                        {itemTitle[2]}
                      </div>
                    </div>
                  </div>
                  <div className="electrified-standalone-menu-wrapper-right">
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-chevron">
                        <img src={rightvector} alt="" />
                      </div>
                    </div>
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-chevron">
                        <img src={rightvector} alt="" />
                      </div>
                    </div>
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-chevron">
                        <img src={rightvector} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="electrified-standalone-menu">
                <div className="deep-gray b2 electrified-standalone-menu-year">
                  2023
                </div>
                <h2 className="h2 electrified-standalone-menu-title">
                  {subtitle[index].replace(
                    "New KONA Electric",
                    "New\nKONA Electric"
                  )}
                </h2>

                <div className="electrified-standalone-menu-wrapper">
                  <div className="electrified-standalone-menu-wrapper-left">
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-title">
                        {learn}
                      </div>
                    </div>
                  </div>
                  <div className="electrified-standalone-menu-wrapper-right">
                    <div className="electrified-standalone-menu-btn">
                      <div className="h4 electrified-standalone-menu-btn-chevron">
                        <img src={rightvector} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="electrified-standalone-bottom">
            <div className="electrified-standalone-list">
              <div className="list-button-index-0">
                <div className={getClassName(0)}>
                  <img
                    className="electrified-standalone-car-img-large"
                    src={`${GET_IMAGE_URL}AllAboutEV_Main.png`}
                    alt=""
                  />
                  <div className="b6 electrified-standalone-car-title">
                    {subtitle[0]}
                  </div>
                </div>
                <div className="list-divider"></div>
              </div>
            </div>
            <div className="electrified-standalone-list">
              <div className={getClassName(1)}>
                <img
                  className="electrified-standalone-car-img"
                  src={`${GET_IMAGE_URL}New_KONA_main.png`}
                  alt=""
                />
                <div className="b6 electrified-standalone-car-title">
                  {subtitle[1]}
                </div>
              </div>
            </div>
            <div className="electrified-standalone-list">
              <div className={getClassName(2)}>
                <img
                  className="electrified-standalone-car-img"
                  src={`${GET_IMAGE_URL}KONA_ELECTRIC_main.png`}
                  alt=""
                />
                <div className="b6 electrified-standalone-car-title">
                  {subtitle[2]}
                </div>
              </div>
            </div>
            <div className="electrified-standalone-list">
              <div className={getClassName(3)}>
                <img
                  className="electrified-standalone-car-img"
                  src={`${GET_IMAGE_URL}IONIQ_5_main.png`}
                  alt=""
                />
                <div className="b6 electrified-standalone-car-title">
                  {subtitle[3]}
                </div>
              </div>
            </div>
            <div className="electrified-standalone-list">
              <div className={getClassName(4)}>
                <img
                  className="electrified-standalone-car-img"
                  src={`${GET_IMAGE_URL}IONIQ5N_main.png`}
                  alt=""
                />
                <div className="b6 electrified-standalone-car-title">
                  {subtitle[4]}
                </div>
              </div>
            </div>
            <div className="electrified-standalone-list">
              <div className={getClassName(5)}>
                <img
                  className="electrified-standalone-car-img"
                  src={`${GET_IMAGE_URL}IONIQ_6_main.png`}
                  alt=""
                />
                <div className="b6 electrified-standalone-car-title">
                  {subtitle[5]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Nav electrified={subtitle} pageClass={itemTitle} />
    </NewWindow>
  );
}

ElectrifiedPage.defaultProps = {
  subtitle: "",
  itemTitle: "",
  index: 0,
  countryCode: false,
  learn: "",
  setFlag: () => {},
};

ElectrifiedPage.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  index: PropTypes.number,
  setFlag: PropTypes.string,
  countryCode: PropTypes.bool,
  learn: PropTypes.string,
};

export default ElectrifiedPage;
