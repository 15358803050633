import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function Template2Aspect11({
  subtitle,
  itemTitle,
  image,
  comment,
  desc,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t2_aspect_1_1_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-aspect11-contents">
        <div className="template-aspect11-contents-items">
          <div className="template-aspect11-contents-items-wrapper">
            <img src={`${GET_IMAGE_URL}${image[0]}.png`} alt="" />
            <div className="b3 template-aspect11-contents-item-comment">
              {comment[0]}
            </div>
          </div>
          <div className="template-aspect11-contents-items-wrapper">
            <img src={`${GET_IMAGE_URL}${image[1]}.png`} alt="" />
            <div className="b3 template-aspect11-contents-item-comment">
              {comment[1]}
            </div>
          </div>
          <div className="template-aspect11-contents-items-wrapper">
            <img src={`${GET_IMAGE_URL}${image[2]}.png`} alt="" />
            <div className="b3 template-aspect11-contents-item-comment">
              {comment[2]}
            </div>
          </div>
        </div>
        <div className="deep-gray template-aspect11-contents-items-desc">
          {desc}
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

Template2Aspect11.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  comment: "",
  desc: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

Template2Aspect11.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  comment: PropTypes.string,
  desc: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default Template2Aspect11;
