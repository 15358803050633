import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateNumberAspect169Vertical({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  desc,
  currentSequence,
  maxSequence,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  return (
    <NewWindow
      title="t2_number_aspect_16_9_vertical_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-number-aspect169-vertical-contents">
        <div className="template-number-aspect169-vertical-contents-top">
          <div>
            <h3 className="brown template-number-aspect169-vertical-contents-top-order-current">
              {currentSequence + 1}
              <span className="h6 template-number-aspect169-vertical-contents-top-order-max">
                /{maxSequence}
              </span>
            </h3>
            <div className="dark-gray h3 template-number-aspect169-vertical-contents-top-title">
              {title}
            </div>
            <div className="deep-gray b2 template-number-aspect169-vertical-contents-top-comment">
              {comment}
            </div>
          </div>
          <div className="deep-gray b6 template-number-aspect169-vertical-contents-top-desc">
            {desc}
          </div>
        </div>
        <div className="template-number-aspect169-vertical-contents-bottom">
          <img src={`${GET_IMAGE_URL}${image[0]}.png`} alt="" />
          <img src={`${GET_IMAGE_URL}${image[1]}.png`} alt="" />
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateNumberAspect169Vertical.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  desc: "",
  currentSequence: 0,
  maxSequence: 0,
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

TemplateNumberAspect169Vertical.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  desc: PropTypes.string,
  currentSequence: PropTypes.number,
  maxSequence: PropTypes.number,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default TemplateNumberAspect169Vertical;
