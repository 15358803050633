import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import rightvector from "assets/images/RightArrow.png";

import DefaultNav from "../nav/defaultNav";

import "./style.css";
import "../common.css";

function GoHomePage({ goHome, setFlag }) {
  return (
    <NewWindow
      title="go_home_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="h3 white go-home-popup">
        <span>{goHome}</span>
        <span>
          <img className="right-vector" src={rightvector} alt="" />
        </span>
      </div>
      <DefaultNav />
    </NewWindow>
  );
}

GoHomePage.defaultProps = {
  goHome: "",
  setFlag: () => {},
};

GoHomePage.propTypes = {
  goHome: PropTypes.string,
  setFlag: PropTypes.string,
};

export default GoHomePage;
