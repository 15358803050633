import { useState } from "react";

import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateAspect169Accordion({
  subtitle,
  itemTitle,
  image,
  header,
  accordionHeader,
  accordionBody,
  desc,
  url,
  setFlag,
  electrified,
  bluelink,
  charge,
}) {
  const [accordionIndex] = useState(0);

  const getClassName = (index, name) => {
    let className = name;

    if (accordionIndex === index) {
      className += "-selected";
      className += className.includes("title") ? " h4" : "";
    } else {
      className += "-unselected h6";
      className += className.includes("title") ? " h6" : "";
    }

    return className;
  };

  return (
    <NewWindow
      title="t2_16-9_accordion_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-aspect169-accordion-contents">
        <div className="template-aspect169-accordion-contents-left">
          <img src={`${GET_IMAGE_URL}${image}.png`} alt="" />
        </div>
        <div>
          <div className="template-aspect169-accordion-contents-right">
            <div className="black h6 template-aspect169-accordion-contents-right-header">
              {header}
            </div>
            <div className="accordion">
              {accordionHeader.map((item, index) => (
                <div>
                  <div className="accordion-wrapper">
                    <div>
                      <div>
                        <div className="accordion-text-header">
                          <div
                            className={getClassName(index, "accordion-title")}
                          >
                            {item}
                          </div>
                          {/* {accordionBody[index] !== "" && (
                            <img
                              className={
                                accordionIndex === index
                                  ? "accordion-text-icon accordion-text-icon-open"
                                  : "accordion-text-icon accordion-text-icon-close"
                              }
                              src={
                                accordionIndex === index
                                  ? UpVectorIcon
                                  : DownVectorIcon
                              }
                              alt=""
                            />
                          )} */}
                        </div>
                        {accordionIndex === index &&
                          accordionBody[index] !== "" && (
                            <div className="deep-gray b3 accordion-comment">
                              {accordionBody[index]}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {(accordionBody[index] !== "" ||
                    index < accordionHeader.length - 1) && (
                    <div className="accordion-divider" />
                  )}
                </div>
              ))}
            </div>
            <div className="deep-gray template-aspect169-accordion-contents-right-desc">
              {desc}{" "}
              <span>
                {url !== undefined &&
                  url !== "(website’s link for WLTP depending on market)" &&
                  `${url === "" ? "" : `(${url})`}`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateAspect169Accordion.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  header: "",
  accordionHeader: [],
  accordionBody: [],
  desc: "",
  url: "",
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
};

TemplateAspect169Accordion.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  header: PropTypes.string,
  accordionHeader: PropTypes.string,
  accordionBody: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.string,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
};

export default TemplateAspect169Accordion;
