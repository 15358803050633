import PropTypes from "prop-types";

import Logo from "assets/images/Logo2.png";

import "./style.css";

function PowertrainNav({ pageTitle }) {
  return (
    <nav className="powertrain-nav">
      <div className="powertrain-nav-left">
        <img className="powertrain-nav-logo-icon" src={Logo} alt="" />
        <div className="contents-nav-left-divider"> </div>
        <div className="h6">{pageTitle}</div>
      </div>
    </nav>
  );
}

PowertrainNav.defaultProps = {
  pageTitle: "",
};

PowertrainNav.propTypes = {
  pageTitle: PropTypes.string,
};

export default PowertrainNav;
