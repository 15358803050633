import Logo from "assets/images/Logo2.png";
// import CalcIcon from "assets/images/calc.png";

import "./style.css";

function DefaultNav() {
  return (
    <nav className="default-nav">
      <span className="default-nav-left">
        <img className="default-nav-logo-icon" src={Logo} alt="" />
      </span>
    </nav>
  );
}

DefaultNav.defaultProps = {};

DefaultNav.propTypes = {};

export default DefaultNav;
