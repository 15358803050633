// import { useState } from "react";
import { Card } from "@mui/material";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function templateInputField(
  originalContent,
  translatedContent,
  onChangeHandler,
  flag,
  index = -1,
  placeholder = ""
) {
  return (
    <MDBox mb="24px">
      <Card style={{ backgroundColor: "#F6F3F2", boxShadow: "none" }}>
        <MDBox p="16px">
          <MDBox mb="12px">
            <MDTypography variant="b8" color="black">
              {originalContent !== undefined
                ? originalContent
                : "Disclaimer(Germany)"}
            </MDTypography>
          </MDBox>
          <MDBox mb="9px">
            <MDInput
              p="14px"
              style={{
                backgroundColor: "#ffffff",
                fontSize: "14px",
                fontWeight: 400,
                borderRadius: "6px",
              }}
              placeholder={index >= 0 ? "" : placeholder}
              multiline
              rows={3}
              fullWidth
              defaultValue={translatedContent}
              onChange={(e) => onChangeHandler(e, index)}
            />
          </MDBox>
          {flag && (
            <MDTypography variant="b4" color="activeRed">
              If longer than 45 characters, text may not be shown properly when
              saved.
            </MDTypography>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default templateInputField;
