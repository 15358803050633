import PropTypes from "prop-types";
import NewWindow from "react-new-window";

function TemplateNotPrepared({ setFlag }) {
  return (
    <NewWindow
      title="template_not_prepared"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div>not prepared</div>
    </NewWindow>
  );
}

TemplateNotPrepared.defaultProps = {
  setFlag: () => {},
};

TemplateNotPrepared.propTypes = {
  setFlag: PropTypes.string,
};

export default TemplateNotPrepared;
