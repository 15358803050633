import PropTypes from "prop-types";
import NewWindow from "react-new-window";

import { GET_IMAGE_URL } from "utils/constants";

import "./style.css";
import "../common.css";

import Nav from "../nav";

function TemplateNumberAspect169Horizontal({
  subtitle,
  itemTitle,
  image,
  title,
  comment,
  desc,
  url,
  currentSequence,
  maxSequence,
  setFlag,
  electrified,
  bluelink,
  charge,
  countryCode,
}) {
  let portu = "";
  if (countryCode) {
    portu = "_portu";
  }
  return (
    <NewWindow
      title="t2_number_aspect_16_9_horizontal_preview"
      features={{
        width: "1600px",
        height: "900px",
      }}
      onUnload={() => setFlag("none")}
    >
      <div className="template-number-aspect169-horizontal-contents">
        <div className="template-number-aspect169-horizontal-contents-top">
          <h3 className="brown template-number-aspect169-horizontal-contents-top-order-current">
            {currentSequence + 1}
            <span className="h6 template-number-aspect169-horizontal-contents-top-order-max">
              /{maxSequence}
            </span>
          </h3>
          <div className="dark-gray h3 template-number-aspect169-horizontal-contents-top-title">
            {title}
          </div>
          <div className="deep-gray b2 template-number-aspect169-horizontal-contents-top-comment">
            {comment}
          </div>
          <div className="deep-gray template-number-aspect169-horizontal-contents-top-desc">
            {desc}{" "}
            <span>
              {url !== undefined &&
                url !== "(website’s link for WLTP depending on market)" &&
                `${url === "" ? "" : `(${url})`}`}
            </span>
          </div>
        </div>
        <div className="template-number-aspect169-horizontal-contents-bottom">
          <div className="template-number-aspect169-horizontal-contents-bottom-image">
            <img src={`${GET_IMAGE_URL}${image[0]}${portu}.png`} alt="" />
          </div>
          <div className="template-number-aspect169-horizontal-contents-bottom-image">
            <img src={`${GET_IMAGE_URL}${image[1]}${portu}.png`} alt="" />
          </div>
        </div>
      </div>
      <Nav
        pageTitle={subtitle}
        pageClass={itemTitle}
        electrified={electrified}
        bluelink={bluelink}
        charge={charge}
      />
    </NewWindow>
  );
}

TemplateNumberAspect169Horizontal.defaultProps = {
  subtitle: "",
  itemTitle: "",
  image: "",
  title: "",
  comment: "",
  desc: "",
  url: "",
  currentSequence: 0,
  maxSequence: 0,
  setFlag: () => {},
  electrified: [],
  bluelink: [],
  charge: [],
  countryCode: false,
};

TemplateNumberAspect169Horizontal.propTypes = {
  subtitle: PropTypes.string,
  itemTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.string,
  currentSequence: PropTypes.number,
  maxSequence: PropTypes.number,
  setFlag: PropTypes.string,
  electrified: PropTypes.string,
  bluelink: PropTypes.string,
  charge: PropTypes.string,
  countryCode: PropTypes.bool,
};

export default TemplateNumberAspect169Horizontal;
